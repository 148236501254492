import React, { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Title from "antd/es/typography/Title";
import { Bar, Pie, Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import "chartjs-plugin-datalabels"; // Import the datalabels plugin
import { useNavigate } from "react-router-dom";
import {
  Card,
  Space,
  Statistic,
  Row,
  Col,
  Modal,
  Divider,
  Collapse,
} from "antd";
import moment from "moment";
import "../../../src/cssFiles/CommonStyles/Common.css";
import "../../../src/cssFiles/CustomStyles/Dashboard.css"
import { getLocalData, removeUnderscoreToSentence } from "../../utils/Common";
import { UseGetRequest, UsePostRequest } from "../../utils/api/ApiRequests";
import {
  GetAllSalaryTypes,
  GetAllEmploymentStatus,
} from "../../utils/api/DropdownFiltersApi";
import EmployeeListModal from "./EmployeeListModal";
import { CalendarOutlined } from "@ant-design/icons";
import viewIcon from "../../assets/images/viewIcon.png";

const backgroundColors = [
  "rgba(54, 162, 235, 0.2)",
  "rgba(255, 99, 132, 0.2)",
  "rgba(255, 206, 86, 0.2)",
  "rgba(75, 192, 192, 0.2)",
  "rgba(153, 102, 255, 0.2)",
];
const borderColors = [
  "rgba(54, 162, 235, 1)",
  "rgba(255, 99, 132, 1)",
  "rgba(255, 206, 86, 1)",
  "rgba(75, 192, 192, 1)",
  "rgba(153, 102, 255, 1)",
];


export default function Dashboard() {
  const dateToday = moment().format("YYYY-MM-DD");
  const [date, setDate] = useState(new Date());
  const navigate = useNavigate();
  const salaryTypes = GetAllSalaryTypes();
  const employmentStatusTypes = GetAllEmploymentStatus();
  const [showEmployeeList, setShowEmployeeList] = useState(false);
  const [employeeList, setEmployeeList] = useState({
    title: "",
    list: [],
  });
  const [showLackingModal, setShowLackingModal] = useState(false);
  const [lackingEmployees, setLackingEmployees] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [birthdays, setBirthdays] = useState([]);
  const [anniversaries, setAnniversaries] = useState([]);
  const [greetings, setGreetings] = useState("");
  const [countReport, setCountReport] = useState({
    women_ratio: 0,
    present_ratio: 0,
    employees_end_of_contract_count: 0,
    employees_end_of_contract: [],
  });
  const [genderPositionData, setGenderPositionData] = useState({
    labels: [],
    datasets: [
      {
        label: "Male",
        data: [],
      },
      {
        label: "Female",
        data: [],
      },
    ],
  });
  const [genderDepartmentData, setGenderDepartmentData] = useState({
    labels: [],
    datasets: [
      {
        label: "Male",
        data: [],
      },
      {
        label: "Female",
        data: [],
      },
    ],
  });
  const [salaryTypeData, setSalaryTypeData] = useState({
    labels: [],
    datasets: [
      {
        label: "Salary Types",
        data: [],
      },
    ],
  });
  const [employmentStatusData, setEmployementStatusData] = useState({
    labels: [],
    datasets: [
      {
        label: "Salary Types",
        data: [],
      },
    ],
  });
  const [civilStatusData, setCivilStatusData] = useState({
    labels: ["single", "married", "widowed"],
    datasets: [
      {
        label: "Civil Status",
        data: [],
      },
    ],
  });
  const [requirementsData, setRequirementsData] = useState({
    labels: [],
    datasets: [
      {
        label: [],
        data: [],
      },
    ],
  });
  const [hireTerminationData, setHireTerminationData] = useState({
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Hired",
        data: [],
      },
      {
        label: "Terminated",
        data: [],
      },
    ],
  });

  const formattedDate = date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  React.useEffect(() => {
    function getGreetings() {
      const user = getLocalData("name");
      const currTime = moment().format("HH:mm:ss");
      const noonStart = new Date(`${dateToday} 12:00:00`);
      const eveningStart = new Date(`${dateToday} 18:00:00`);
      const mornStart = new Date(`${dateToday} 00:00:00`);
      const currentTime = new Date(`${dateToday} ${currTime}`);

      if (currentTime >= mornStart && currentTime < noonStart) {
        setGreetings(`Good Morning, ${user}! 🌞`);
      } else if (currentTime >= noonStart && currentTime < eveningStart) {
        setGreetings(`Good Afternoon, ${user}! 🕛`);
      } else {
        setGreetings(`Good Evening, ${user}! 🌝`);
      }
    }

    async function fetchBirthdaysAndAnniversaries() {
      const response = await UsePostRequest("employees/get_all", {});
      if (response.data) {
        setAllEmployees(response.data);
        const currentMonth = moment().format("MMMM");

        const upcomingBirthdays = response.data.filter((employee) => {
          const birthdate = moment(employee.birthdate).format("MMMM");
          return birthdate === currentMonth;
        });
        const filteredBdays = upcomingBirthdays.sort((a, b) => {
          const dayA = moment(a.birthdate).format("DD");
          const dayB = moment(b.birthdate).format("DD");
          return dayA - dayB;
        });

        const upcomingAnniversaries = response.data.filter((employee) => {
          const monthHired = moment(employee.date_hired).format("MMMM");
          return monthHired === currentMonth;
        });
        const filteredAnnivs = upcomingAnniversaries.sort((a, b) => {
          const dayA = moment(a.date_hired).format("DD");
          const dayB = moment(b.date_hired).format("DD");
          return dayA - dayB;
        });

        setBirthdays(filteredBdays);
        setAnniversaries(filteredAnnivs);
      }
    }

    getGreetings();
    fetchBirthdaysAndAnniversaries();
  }, []);

  React.useEffect(() => {
    async function fetchPositionChartData() {
      let tempValues = {
        labels: [],
        male: [],
        female: [],
      };
      const response = await UseGetRequest("positions/get", {});
      if (response.data) {
        response.data.data?.forEach((row, index) => {
          tempValues.labels.push(row.name);
          let maleCount = allEmployees.filter(
            (employee) =>
              employee.position_name?.toLowerCase() === row.name?.toLowerCase() &&
              employee.gender?.toLowerCase() === "male"
          );
          let femaleCount = allEmployees.filter(
            (employee) =>
              employee.position_name?.toLowerCase()=== row.name?.toLowerCase() &&
              employee.gender?.toLowerCase() === "female"
          );
          tempValues.male.push(maleCount.length);
          tempValues.female.push(femaleCount.length);
        });
      }
      const tempData = {
        labels: tempValues.labels,
        datasets: [
          {
            label: "Male",
            data: tempValues.male,
            backgroundColor: ["rgba(54, 162, 235, 0.2)"],
            borderColor: ["rgba(54, 162, 235, 1)"],
            borderWidth: 1,
          },
          {
            label: "Female",
            data: tempValues.female,
            backgroundColor: ["rgba(255, 99, 132, 0.2)"],
            borderColor: ["rgba(255, 99, 132, 1)"],
            borderWidth: 1,
          },
        ],
      };

      setGenderPositionData(tempData);
    }

    async function fetchDepartmentChartData() {
      let tempValues = {
        labels: [],
        male: [],
        female: [],
      };
      const response = await UseGetRequest("departments/get", {});
      if (response.data) {
        
        response.data.data?.forEach((row) => {
          tempValues.labels.push(row.name);
          let maleCount = [];
          let femaleCount = [];
          allEmployees.forEach((emp) => {
            if (emp.department_name?.toLowerCase() === row.name?.toLowerCase()) {
              if (emp.gender === "male") {
                maleCount.push(emp);
              } else {
                femaleCount.push(emp);
              }
            }
          });
          tempValues.male.push(maleCount.length);
          tempValues.female.push(femaleCount.length);
        });
      }
      const tempData = {
        labels: tempValues.labels,
        datasets: [
          {
            label: "Male",
            data: tempValues.male,
            backgroundColor: ["rgba(54, 162, 235, 0.2)"],
            borderColor: ["rgba(54, 162, 235, 1)"],
            borderWidth: 1,
          },
          {
            label: "Female",
            data: tempValues.female,
            backgroundColor: ["rgba(255, 99, 132, 0.2)"],
            borderColor: ["rgba(255, 99, 132, 1)"],
            borderWidth: 1,
          },
        ],
      };

      setGenderDepartmentData(tempData);
    }

    async function fetchSalaryTypeChartData() {
      let tempValues = {
        labels: [],
        data: [],
      };

      salaryTypes?.slice(1)?.forEach((row) => {
        tempValues.labels.push(row.label);
        let type = allEmployees.filter(
          (employee) => employee.salary_type === row.value
        );
        tempValues.data.push(type.length);
      });

      const tempData = {
        labels: tempValues.labels,
        datasets: [
          {
            label: "Salary Types",
            data: tempValues.data,
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            borderWidth: 1,
          },
        ],
      };

      setSalaryTypeData(tempData);
    }

    async function fetchEmploymentStatusData() {
      let tempValues = {
        labels: [],
        data: [],
      };

      employmentStatusTypes?.slice(1)?.forEach((row) => {
        tempValues.labels.push(row.label);
        let type = allEmployees.filter(
          (employee) => employee.employment_status_name?.toLowerCase() === row.name?.toLowerCase()
        );
        tempValues.data.push(type.length);
      });

      const tempData = {
        labels: tempValues.labels,
        datasets: [
          {
            label: "Salary Types",
            data: tempValues.data,
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            borderWidth: 1,
          },
        ],
      };

      setEmployementStatusData(tempData);
    }

    function fetchCivilStatusData() {
      let tempValues = {
        labels: ["Single", "Married", "Widowed"],
        data: [],
      };

      tempValues.labels.forEach((row) => {
        let type = allEmployees.filter(
          (employee) =>
            employee.civil_status?.toLowerCase() === row.toLowerCase()
        );
        tempValues.data.push(type.length);
      });

      const tempData = {
        labels: tempValues.labels,
        datasets: [
          {
            label: "Civil Status",
            data: tempValues.data,
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            borderWidth: 1,
          },
        ],
      };
      setCivilStatusData(tempData);
    }

    async function fetchDashboardReport() {
      const dashReports = await UseGetRequest("dashboard/get_reports", {
        date: moment().format("YYYY-MM-DD"),
      });
      if (dashReports.data) {

        setCountReport((prev) => ({
          ...prev,
          present_ratio: dashReports.data.attendance_rate_count,
          employees_end_of_contract_count:
            dashReports.data.employees_end_of_contract_count,
          employees_end_of_contract: dashReports.data.employees_end_of_contract,
        }));

        const tempHires = dashReports.data.hire_count_monthly[0];
        const tempTerminations =
          dashReports.data.resigned_terminated_monthly_count[0];

        const tempHireTerminatedData = {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "July",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          datasets: [
            {
              label: "Hired",
              data: Object.keys(tempHires).map((key) =>
                parseInt(tempHires[key])
              ),
              fill: true,
              tension: 0.1,
              borderWidth: 1.3,
              backgroundColor: backgroundColors[0],
              borderColor: borderColors[0],
            },
            {
              label: "Terminated",
              data: Object.keys(tempTerminations).map((key) =>
                parseInt(tempTerminations[key])
              ),
              fill: false,
              tension: 0.1,
              borderWidth: 1.3,
              backgroundColor: backgroundColors[1],
              borderColor: borderColors[1],
            },
          ],
        };

        setHireTerminationData(tempHireTerminatedData);
      }
    }

    if (allEmployees && allEmployees.length > 0) {
      const womenCount = allEmployees.filter(
        (employee) => employee.gender?.toLowerCase() === "female"
      );
      const tempRatio =
        (parseFloat(womenCount.length) / parseFloat(allEmployees.length)) * 100;
      setCountReport((prev) => ({ ...prev, women_ratio: tempRatio }));

      fetchPositionChartData();
      fetchDepartmentChartData();
      fetchSalaryTypeChartData();
      fetchEmploymentStatusData();
      fetchCivilStatusData();
      fetchDashboardReport();
    }
  }, [allEmployees]);

  async function fetchLackingReqs() {
    let tempReqValues = {
      labels: [],
      data: [],
    };

    const reqResponse = await UseGetRequest(
      "dashboard/get_requirement_lacking_count"
    );
    if (reqResponse.data) {
      const modalData = [];
      reqResponse.data.reports?.forEach((row) => {
        let name = removeUnderscoreToSentence(row.file_type);
        tempReqValues.labels.push(name);
        tempReqValues.data.push(row.count);

        var tempModalData = {
          key: row.file_type,
          label: name,
          children: (
            <ul>
              {row.employees?.map((child) => (
                <li>{child}</li>
              ))}
            </ul>
          ),
        };
        modalData.push(tempModalData);
      });
      setLackingEmployees(modalData);

      const tempReqData = {
        labels: tempReqValues.labels,
        datasets: [
          {
            label: "Lacking Requirements",
            data: tempReqValues.data,
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            borderWidth: 1,
          },
        ],
      };
      setRequirementsData(tempReqData);
    }
  }

  React.useEffect(() => {
    Chart.register({ type: "category", id: "x" }); // Register category scale
    fetchLackingReqs();
  }, []);

  return (
    <Navbar>
      <Space className="d-flex justify-content-between p-3" wrap>
        <Title level={3}>{greetings}</Title>
        <Title level={4} className="pointer-cursor calendar-button" onClick={() => navigate("eventscalendar")}>
          <CalendarOutlined className="me-2" /> {formattedDate}, {formattedTime}
        </Title>
      </Space>

      <Row className="p-3">
        <Col className="p-1" xs={12} md={8} lg={4}>
          <Card>
            <Statistic title="Total Employee" value={allEmployees.length} />
          </Card>
        </Col>
        <Col className="p-1" xs={12} md={8} lg={4}>
          <Card>
            <Statistic
              title="Woman Employee"
              value={`${parseFloat(countReport.women_ratio).toFixed(2)} %`}
            />
          </Card>
        </Col>
        <Col className="p-1" xs={12} md={8} lg={4}>
          <Card>
            <Statistic
              title="Attendance Rate"
              value={`${parseFloat(countReport.present_ratio).toFixed(2)} %`}
            />
          </Card>
        </Col>

        <Col className="p-1" xs={12} md={8} lg={4}>
          <Card
            className="pointer-cursor"
            onClick={() => {
              setEmployeeList({
                list: countReport.employees_end_of_contract,
                title: "End of Contract",
                focusDate: "end_of_contract",
                header: "DATE",
              });
              setShowEmployeeList(true);
            }}
          >
            <Statistic
              title="End of Contract"
              value={countReport.employees_end_of_contract_count}
            />
          </Card>
        </Col>

        <Col className="p-1" xs={12} md={8} lg={4}>
          <Card
            className="pointer-cursor"
            onClick={() => {
              setEmployeeList({
                list: birthdays,
                title: "Upcoming Birthdays",
                focusDate: "birthdate",
                header: "BIRTHDATE",
                countColumn: "AGE",
              });
              setShowEmployeeList(true);
            }}
          >
            <Statistic title="Birthdays" value={birthdays.length} />
          </Card>
        </Col>
        <Col className="p-1" xs={12} md={8} lg={4}>
          <Card
            className="pointer-cursor"
            onClick={() => {
              setEmployeeList({
                list: anniversaries,
                title: "Upcoming Anniversaries",
                focusDate: "date_hired",
                header: "DATE HIRED",
                countColumn: "YEAR",
              });
              setShowEmployeeList(true);
            }}
          >
            <Statistic title="Work Anniversary" value={anniversaries.length} />
          </Card>
        </Col>
      </Row>
      {/* BAR GRAPHS */}
      <Row>
        <Col className="p-1" xs={24} md={24} lg={12}>
          <Card>
            <Bar
              data={genderPositionData}
              className="w-100"
              options={{
                plugins: {
                  title: {
                    display: true,
                    text: "Employees by Gender & Position",
                  },
                },
                scales: {
                  x: {
                    type: "category",
                    stacked: false,
                    title: {
                      display: true,
                      text: "Positions",
                    },
                  },
                  y: {
                    beginAtZero: true,
                    stacked: false,
                    title: {
                      display: true,
                      text: "Number of Employees",
                    },
                  },
                },
              }}
            />
          </Card>
        </Col>
        <Col className="p-1" xs={24} md={24} lg={12}>
          <Card>
            <Bar
              data={genderDepartmentData}
              className="w-100"
              options={{
                plugins: {
                  title: {
                    display: true,
                    text: "Employees by Gender & Department",
                  },
                },
                scales: {
                  x: {
                    type: "category",
                    stacked: false,
                    title: {
                      display: true,
                      text: "Departments",
                    },
                  },
                  y: {
                    beginAtZero: true,
                    stacked: false,
                    title: {
                      display: true,
                      text: "Number of Employees",
                    },
                  },
                },
              }}
            />
          </Card>
        </Col>
        <Col className="p-1" xs={24} md={24} lg={12}>
          <Card>
            <Row className="d-flex justify-content-end mb-0 pb-0 view-icon">
              {/* <EyeOutlined
                className="pointer-cursor"
                onClick={() => setShowLackingModal(true)}
              /> */}
              <Col xs={1} className="">
                <img src={viewIcon} alt="icon" className="view-icon" onClick={() => setShowLackingModal(true)}/>
              </Col>
            </Row>
            <Bar
              data={requirementsData}
              className="w-100"
              options={{
                plugins: {
                  title: {
                    display: true,
                    text: "Lacking Requirements",
                  },
                },
                scales: {
                  x: {
                    type: "category",
                    stacked: false,
                    title: {
                      display: true,
                      text: "File Types",
                    },
                  },
                  y: {
                    beginAtZero: true,
                    stacked: false,
                    title: {
                      display: true,
                      text: "Number of Employees",
                    },
                  },
                },
              }}
            />
          </Card>
        </Col>
        <Col className="p-1" xs={24} md={24} lg={12}>
          <Card>
            <Line
              data={hireTerminationData}
              className="w-100 mt-4"
              options={{
                plugins: {
                  title: {
                    display: true,
                    text: "Hires and Terminations",
                  },
                },
                scales: {
                  x: {
                    type: "category",
                    stacked: false,
                    title: {
                      display: true,
                      text: "Months",
                    },
                  },
                  y: {
                    beginAtZero: true,
                    stacked: false,
                    title: {
                      display: true,
                      text: "Number of Employees",
                    },
                  },
                },
              }}
            />
          </Card>
        </Col>
      </Row>
      {/* PIE GRAPHS */}
      <Row>
        <Col className="p-1" xs={24} lg={8}>
          <Card>
            <Pie
              data={salaryTypeData}
              options={{
                plugins: {
                  title: {
                    display: true,
                    text: "Salary Types",
                  },
                  legend: {
                    display: true,
                    position: "top",
                  },
                  tooltip: {
                    callbacks: {
                      label: (tooltipItem) => {
                        const dataset = tooltipItem.dataset;
                        const total = dataset.data.reduce(
                          (acc, val) => acc + val,
                          0
                        );
                        const currentValue = tooltipItem.raw;
                        const percentage = Math.round(
                          (currentValue / total) * 100
                        );
                        return `${tooltipItem.label}: ${currentValue} (${percentage}%)`;
                      },
                    },
                  },
                },
              }}
            />
          </Card>
        </Col>
        <Col className="p-1" xs={24} lg={8}>
          <Card>
            <Pie
              data={employmentStatusData}
              options={{
                plugins: {
                  title: {
                    display: true,
                    text: "Employment Status Types",
                  },
                  legend: {
                    display: true,
                    position: "top",
                  },
                  tooltip: {
                    callbacks: {
                      label: (tooltipItem) => {
                        const dataset = tooltipItem.dataset;
                        const total = dataset.data.reduce(
                          (acc, val) => acc + val,
                          0
                        );
                        const currentValue = tooltipItem.raw;
                        const percentage = Math.round(
                          (currentValue / total) * 100
                        );
                        return `${tooltipItem.label}: ${currentValue} (${percentage}%)`;
                      },
                    },
                  },
                },
              }}
            />
          </Card>
        </Col>
        <Col className="p-1" xs={24} lg={8}>
          <Card>
            <Pie
              data={civilStatusData}
              options={{
                plugins: {
                  title: {
                    display: true,
                    text: "Civil Status",
                  },
                  legend: {
                    display: true,
                    position: "top",
                  },
                  tooltip: {
                    callbacks: {
                      label: (tooltipItem) => {
                        const dataset = tooltipItem.dataset;
                        const total = dataset.data.reduce(
                          (acc, val) => acc + val,
                          0
                        );
                        const currentValue = tooltipItem.raw;
                        const percentage = Math.round(
                          (currentValue / total) * 100
                        );
                        return `${tooltipItem.label}: ${currentValue} (${percentage}%)`;
                      },
                    },
                  },
                },
              }}
            />
          </Card>
        </Col>
      </Row>

      <EmployeeListModal
        show={showEmployeeList}
        onCancel={() => setShowEmployeeList(false)}
        data={employeeList}
        focusDate={employeeList.focusDate}
        header={employeeList.header}
        countColumn={employeeList.countColumn}
      />
      <Modal
        open={showLackingModal}
        onCancel={() => setShowLackingModal(false)}
        onOk={() => setShowLackingModal(false)}
      >
        <Title level={5}>Lacking Requirements</Title>
        <Divider />
        <Collapse items={lackingEmployees} />
      </Modal>
    </Navbar>
  );
}
