import React, { useState } from "react"
import { Row, Col, Card, Upload, Modal, Button, Typography, Spin } from "antd"
import { CloudUploadOutlined } from "@ant-design/icons"
import { useLocation, useNavigate } from "react-router-dom"
import Navbar from "../../components/Navbar/Navbar"
import { usePostRequest } from "../../hooks/usePostRequest"
import { capitalizeEachWord } from "../../utils/Common"
import { uploadTimesheet } from "../../utils/api/Auth"
import toast from "react-hot-toast"
import moment from "moment"
import * as XLSX from "xlsx"

const { Dragger } = Upload
const { Title } = Typography

export default function UploadTimesheet() {
  const navigate = useNavigate()
  const location = useLocation()
  const formPurpose = location.state
  const [fileList, setFileList] = useState([])
  const [fileSizeExceeded, setFileSizeExceeded] = useState(false)
  // const [modal, contextHolder] = Modal.useModal();
  const { callPost, isLoading } = usePostRequest(() => {
    navigate("/timesheet")
  })
  const [saving, setSaving] = useState(false)

  const handleRemove = (selectedFile) => {
    var newlist = fileList.filter((file) => {
      return selectedFile.uid !== file.uid
    })
    setFileList(newlist)
  }

  const handleBeforeUpload = (file) => {
    const isSizeValid = file.size / 1024 / 1024 < 10

    if (!isSizeValid) {
      setFileSizeExceeded(true)
      return Upload.LIST_IGNORE
    } else {
      setFileList([file])
    }
    return false
  }

  function handleUploadTimesheet() {
    setSaving(true)
    const file = fileList[0]
    const extension = file.name.split(".")[1]
    if (file && (extension === "xlsx" || extension === "xls")) {
      const reader = new FileReader()
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result)
        const workbook = XLSX.read(data, { type: "array" })
        const worksheet = workbook.Sheets[workbook.SheetNames[0]]
        // Convert the worksheet to JSON, while keeping the original formats
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
          raw: false,
          dateNF: "MM/DD/YYYY",
        })

        const arrayOfObj = jsonData.map((row) => {
          return {
            dtr_id: row.DTR,
            work_date: moment(row.Date).format("MM/DD/YYYY"),
            time_in: moment(row.Login, "HH:mm").format("HH:mm"),
            time_out: moment(row.Logout, "HH:mm").format("HH:mm"),
          }
        })
        processTimesheets(arrayOfObj)
      }
      reader.readAsArrayBuffer(file)
    }
  }

  function chunkArray(array, chunkSize) {
    const chunks = []
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize))
    }
    return chunks
  }

  const processTimesheets = async (arrayOfObj) => {
    const chunkedArray = chunkArray(arrayOfObj, 150)
    const promises = []
    for (const chunk in chunkedArray) {
      promises.push(await sendTimesheet(chunkedArray[chunk]))
    }

    try {
      await Promise.all(promises)
      toast.success("Successfully uploaded timesheet")
      setTimeout(() => {
        navigate("/timesheet")
      }, 500)
      setSaving(false)
    } catch (error) {
      toast.error("Error uploading timesheet")
      setSaving(false)
    }
  }

  async function sendTimesheet(arrayData) {
    try {
      const response = await uploadTimesheet({ data: arrayData })
      if (response.data) {
        response.data.response?.forEach((res) => {
          if (!res?.toLowerCase().includes("successfully")) {
            toast.error(res, { duration: 2000 })
          }
        })
      }
      return response
    } catch (error) {
      toast.error(`Error: ${error.data || error.message}`, { duration: 2000 })
      throw error
    }
  }

  return (
    <Navbar formPageTitle="Upload Timesheet" isForm>
      <Card>
        <Title level={3}>{capitalizeEachWord("upload " + formPurpose)}</Title>
        <Row className="mt-3">
          <Col xs={24}>
            <Dragger
              {...{
                fileList: fileList,
                onRemove: handleRemove,
                beforeUpload: handleBeforeUpload,
                multiple: false,
                listType: "picture",
                progress: { showInfo: true },
                data: (file) => {},
                accept: ".xlsx, .xls",
                maxCount: 1,
              }}
            >
              <CloudUploadOutlined className="upload-icon" />
              <p className="ant-upload-text">Browse Files</p>
            </Dragger>
          </Col>
        </Row>

        <Row className="d-flex justify-content-end mt-4">
          <Row>
            <Button
              danger
              className="me-2"
              onClick={() => navigate("/timesheet")}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={handleUploadTimesheet}
              disabled={fileList.length === 0}
              loading={isLoading}
            >
              Upload
            </Button>
          </Row>
        </Row>
      </Card>

      <Modal
        open={fileSizeExceeded}
        title={""}
        footer={null}
        onCancel={() => setFileSizeExceeded(false)}
      >
        File size must not exceed 5MB.
      </Modal>

      <Modal maskClosable={false} open={saving} footer={null} closeIcon={false}>
        <Row className="d-flex justify-content-center">
          <Title level={5}>Uploading Timesheet</Title>
        </Row>
        <Row className="d-flex justify-content-center mt-3">
          <Spin size="large" />
        </Row>
        <Row className="d-flex justify-content-center mt-3">
          Please do not close this window.
        </Row>
      </Modal>
    </Navbar>
  )
}
