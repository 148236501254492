import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import {
  Button,
  Form,
  Input,
  Modal,
  Table,
  Row,
  Select,
  DatePicker,
  Typography,
  Checkbox,
  Space,
  Spin,
} from "antd";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { usePostRequest } from "../../hooks/usePostRequest";
import { UseGetRequest, UsePostRequest } from "../../utils/api/ApiRequests";
import {
  formatAmount,
  formatShortDate,
  capitalizeEachWord,
  getIsAdminLogin,
  getEmployeeId,
  minsToHours,
  removeUnderscoreToSentence,
} from "../../utils/Common";
import { GetAllSalaryTypes } from "../../utils/api/DropdownFiltersApi";
import dayjs from "dayjs";
import html2pdf from "html2pdf.js";
import { useReactToPrint } from "react-to-print";
import PayslipPdf from "./PayslipPdf";
import moment from "moment";
import { useTheme, useMediaQuery } from "@mui/material";

const Title = Typography;

const AddSubmitButton = ({ form, isLoading }) => {
  const [submittable, setSubmittable] = useState(false);

  // Watch all values
  const values = Form.useWatch([], form);
  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
  }, [values]);
  return (
    <Button
      type="primary"
      htmlType="submit"
      disabled={!submittable}
      loading={isLoading}
    >
      Proceed
    </Button>
  );
};

export default function Payroll() {
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  // Get the screen size
  const theme = useTheme();
  const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const currentDate = new Date();
  const navigate = useNavigate();
  const componentRef = useRef();
  const bulkPrintRefs = useRef([]);
  const isAdminLogin = getIsAdminLogin();
  const employeeId = getEmployeeId();
  const { callPost, isLoading } = usePostRequest(() => {
    setShowAddModal(false);
    setShowViewModal(false);
    setFilterConfig((prev) => ({ ...prev, is_updated: !prev.is_updated }));
  });
  const [showAddModal, setShowAddModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [pdfGenerationInProgress, setPdfGenerationInProgress] = useState(false);
  const [clickedRowData, setClickedRowData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [verticalTableData, setVerticalTableData] = useState([]);
  const [printTableData, setPrintTableData] = useState([]);
  const [modal, contextHolder] = Modal.useModal();
  const [searchTerm, setSearchTerm] = useState(null);
  const [showDeleteMultiple, setShowDeleteMultiple] = useState(false);
  const [itemsToDelete, setItemsToDelete] = useState([]);
  // const businessUnitOptions = GetAllBusinessUnits();
  const salaryOptions = GetAllSalaryTypes();
  const [generatePdf, setGeneratePdf] = useState(false);
  const [payrollExcelUrl, setPayrollExcelUrl] = useState();

  function getFirstDayOfMonth() {
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    return moment(date).format("YYYY-MM-DD");
  }

  function get15thDayOfMonth() {
    const date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      15
    );
    return moment(date).format("YYYY-MM-DD");
  }

  function get16thDayOfMonth() {
    const date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      16
    );
    return moment(date).format("YYYY-MM-DD");
  }

  function getLastDayOfMonth() {
    const date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    return moment(date).format("YYYY-MM-DD");
  }

  const [filterConfig, setFilterConfig] = useState({
    is_updated: false,
    employee_id: window.$isEmployeeLogin ? employeeId : "",
    date_from:
      currentDate.getDate() <= 15 ? getFirstDayOfMonth() : get16thDayOfMonth(),
    date_to:
      currentDate.getDate() <= 15 ? get15thDayOfMonth() : getLastDayOfMonth(),
  });

  const [isFetching, setIsFetching] = useState(false);

  const tableColumns = [
    {
      title: "",
      dataIndex: "payroll_id",
      key: "payroll_id",
      fixed: "left",
      render: (id) => (
        <div>
          {showDeleteMultiple && (
            <Checkbox
              onChange={(e) =>
                e.target.checked
                  ? setItemsToDelete([...itemsToDelete, id])
                  : setItemsToDelete(
                      itemsToDelete.filter((item) => item !== id)
                    )
              }
            />
          )}
        </div>
      ),
    },
    {
      title: "Date From",
      dataIndex: "date_from",
      key: "date_from",
      render: (data, record) => (
        <div onClick={() => handleRowClick(record)} className="pointer-cursor">
          {formatShortDate(data)}
        </div>
      ),
    },
    {
      title: "Date To",
      dataIndex: "date_to",
      key: "date_to",
      render: (data, record) => (
        <div onClick={() => handleRowClick(record)} className="pointer-cursor">
          {formatShortDate(data)}
        </div>
      ),
    },
    {
      title: "Employee",
      dataIndex: "employee_name",
      key: "employee_name",
      render: (employee_name, record) => (
        <div onClick={() => handleRowClick(record)} className="pointer-cursor">
          {capitalizeEachWord(employee_name)}
        </div>
      ),
    },
    {
      title: "Total Earnings",
      dataIndex: "total_earnings",
      key: "total_earnings",
      align: "right",
      render: (total_earnings, record) => (
        <div onClick={() => handleRowClick(record)} className="pointer-cursor">
          {formatAmount(total_earnings)}
        </div>
      ),
    },
    {
      title: "Total Deductions",
      dataIndex: "total_deduction",
      key: "total_deduction",
      align: "right",
      render: (total_deduction, record) => (
        <div onClick={() => handleRowClick(record)} className="pointer-cursor">
          {formatAmount(total_deduction)}
        </div>
      ),
    },
    {
      title: "Total Payout",
      dataIndex: "total_payout",
      key: "total_payout",
      align: "right",
      render: (total_payout, record) => (
        <div onClick={() => handleRowClick(record)} className="pointer-cursor">
          {formatAmount(total_payout)}
        </div>
      ),
    },
  ];

  const keyValuePairColumns = [
    {
      title: "",
      dataIndex: "header",
      key: "header",
      render: (data, record) => (
        <div onClick={() => handleRowClick(record)} className="bold">
          {removeUnderscoreToSentence(data)}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "value",
      key: "value",
      render: (value, record) => (
        <div onClick={() => handleRowClick(record)} className="pointer-cursor">
          {value}
        </div>
      ),
    },
  ];

  const rowClassName = (record, index) => {
    const isLastRow = index === printTableData.length - 1;
    return isLastRow ? "bold-cell" : "";
  };

  // specific for the print page only
  const printTableColumns = [
    {
      title: "Date From",
      dataIndex: "date_from",
      key: "date_from",
      render: (date_from, record) => (
        <div>{date_from && date_from !== "" && formatShortDate(date_from)}</div>
      ),
    },
    {
      title: "Date To",
      dataIndex: "date_to",
      key: "date_to",
      render: (date_to, record) => (
        <div>{date_to && date_to !== "" && formatShortDate(date_to)}</div>
      ),
    },
    {
      title: "Employee",
      dataIndex: "employee_name",
      key: "employee_name",
    },
    {
      title: "Basic Pay",
      dataIndex: "basic_pay",
      key: "basic_pay",
      align: "right",
      render: (basic_pay, record) => <div>{formatAmount(basic_pay)}</div>,
    },
    {
      title: "Holiday Pay",
      dataIndex: "holiday_pay",
      key: "holiday_pay",
      align: "right",
      render: (holiday_pay, record) => <div>{formatAmount(holiday_pay)}</div>,
    },
    {
      title: "Regular OT (mins)",
      dataIndex: "regular_ot_mins",
      key: "regular_ot_mins",
      render: (regular_ot_mins, record) => (
        <div>{formatAmount(regular_ot_mins)}</div>
      ),
    },
    {
      title: "Regular OT",
      dataIndex: "regular_ot",
      key: "regular_ot",
      align: "right",
      render: (regular_ot, record) => <div>{formatAmount(regular_ot)}</div>,
    },
    {
      title: "Restday OT (mins)",
      dataIndex: "restday_ot_mins",
      key: "restday_ot_mins",
      render: (restday_ot_mins, record) => (
        <div>{formatAmount(restday_ot_mins)}</div>
      ),
    },
    {
      title: "Restday OT",
      dataIndex: "restday_ot",
      key: "restday_ot",
      align: "right",
      render: (restday_ot, record) => <div>{formatAmount(restday_ot)}</div>,
    },
    {
      title: "Total Earnings",
      dataIndex: "total_earnings",
      key: "total_earnings",
      align: "right",
      render: (total_earnings, record) => (
        <div>{formatAmount(total_earnings)}</div>
      ),
    },
    {
      title: "SSS",
      dataIndex: "sss_contribution",
      key: "sss_contribution",
      align: "right",
      render: (sss_contribution, record) => (
        <div>{formatAmount(sss_contribution)}</div>
      ),
    },
    {
      title: "PhilHealth",
      dataIndex: "philhealth_contribution",
      key: "philhealth_contribution",
      align: "right",
      render: (philhealth_contribution, record) => (
        <div>{formatAmount(philhealth_contribution)}</div>
      ),
    },
    {
      title: "PagIbig",
      dataIndex: "pagibig_contribution",
      key: "pagibig_contribution",
      align: "right",
      render: (pagibig_contribution, record) => (
        <div>{formatAmount(pagibig_contribution)}</div>
      ),
    },
    {
      title: "Late",
      dataIndex: "late",
      key: "late",
      align: "right",
      render: (late, record) => <div>{formatAmount(late)}</div>,
    },
    {
      title: "Undertime",
      dataIndex: "undertime_amount",
      key: "undertime_amount",
      align: "right",
      render: (undertime_amount, record) => (
        <div>{formatAmount(undertime_amount)}</div>
      ),
    },
    {
      title: "Total Deductions",
      dataIndex: "total_deduction",
      key: "total_deduction",
      align: "right",
      render: (total_deduction, record) => (
        <div>{formatAmount(total_deduction)}</div>
      ),
    },
    {
      title: "Total Payout",
      dataIndex: "total_payout",
      key: "total_payout",
      align: "right",
      render: (total_payout, record) => <div>{formatAmount(total_payout)}</div>,
    },
  ];

  const employeeActions = [
    {
      value: "print",
      label: "Print Report",
      action: () => fetchPrintExportData("print"),
    },
  ]

  const actionDropdownOptions = [
    isAdminLogin && {
      value: "generate",
      label: "Add Payroll",
      action: () => setShowAddModal(true),
    },
    {
      value: "download",
      label: "Download Payslips",
      action: () => {
        setGeneratePdf(true);
        handleDownloadPDF();
      },
    },
    {
      value: "print",
      label: "Print Report",
      action: () => fetchPrintExportData("print"),
    },
    {
      value: "export",
      label: (
        <a href={payrollExcelUrl} className="pointer no-style-btn">
          Export Payroll Summary
        </a>
      ),
    },
    isAdminLogin && {
      value: "delete",
      label: "Delete Multiple",
      action: () => setShowDeleteMultiple(true),
    },
  ];

  const confirmDelete = (payroll) => {
    modal.confirm({
      title: `Are you sure you want to delete this payroll?`,
      icon: <ExclamationCircleOutlined />,
      content: "You cannot undo this action.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: () => handleDelete(payroll.payroll_id),
      okType: "danger",
    });
  };

  function handleMultipleDelete() {
    itemsToDelete.forEach((item) => {
      handleDelete(item);
    });
  }

  const confirmMultipleDelete = (e) => {
    modal.confirm({
      title: `Are you sure you want to delete the selected payrolls?`,
      icon: <ExclamationCircleOutlined />,
      content: "You cannot undo this action.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: () => handleMultipleDelete(),
      okType: "danger",
    });
  };

  const onFinish = (formInputs) => {
    setShowAddModal(false);
    navigate("/payrolls/generate", { state: formInputs });
  };

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
    }
  };

  function handleDelete(passedId) {
    callPost("payrolls/delete/" + passedId, {}, false);
  }

  const handleRowClick = (rowData) => {
    if (isAdminLogin) {
      setClickedRowData(rowData);
      const finalValues = { ...rowData };
      finalValues.date_from = rowData.date_from
        ? dayjs(rowData.date_from, "YYYY-MM-DD")
        : "";
      finalValues.date_to = rowData.date_to
        ? dayjs(rowData.date_to, "YYYY-MM-DD")
        : "";
      editForm.setFieldsValue(finalValues);
      setShowViewModal(true);
    } else {
      window.open("/payrolls/payslip/" + rowData.payroll_id, "_blank");
    }
  };

  const handleDateFilter = (e) => {
    if (e) {
      setFilterConfig((prev) => ({
        ...prev,
        date_from: e[0].format("YYYY-MM-DD"),
        date_to: e[1].format("YYYY-MM-DD"),
      }));
    }
  };

  const handleDownloadPDF = async () => {
    setPdfGenerationInProgress(true);

    setTimeout(async () => {
      for (let i = 0; i < tableData.length; i++) {
        const payroll = tableData[i];
        const element = bulkPrintRefs.current[i];
        element.classList.remove("printOnly");
        var firstName = payroll.employee_name.trim();
        var dateCovered = `${
          moment(payroll.date_from).format("MMM DD") +
          " to " +
          moment(payroll.date_to).format("DD, YYYY")
        }`;
        var payslipName = `${firstName.trim() + "_Payslip_" + dateCovered}`;
        const options = {
          // filename: `${payroll.employee_name}_payslip.pdf`,
          filename: `${payslipName}.pdf`,
          pageOrientation: "landscape",
          html2canvas: { scale: 3, dpi: 1200 },
        };

        await html2pdf().set(options).from(element).save();
        await new Promise((resolve) => setTimeout(resolve, 500));
        element.classList.add("printOnly");
      }
      setPdfGenerationInProgress(false);
      setGeneratePdf(false);
      bulkPrintRefs.current = [];
    }, 3000);
  };

  const handleBulkPrintRef = (ref) => {
    bulkPrintRefs.current.push(ref);
  };

  function renderPdfGenerator() {
    if (generatePdf) {
      return (
        <Row className="always-hidden">
          {tableData.map((payroll, index) => (
            <PayslipPdf
              key={index}
              setBulkPrintRef={handleBulkPrintRef}
              payroll_id={payroll.payroll_id}
              hide="printOnly"
            />
          ))}
        </Row>
      );
    }
  }

  function renderPrintTable() {
    return (
      <div>
        <Title level={5} className="px-4">
          PAYROLL
        </Title>
        <Table
          className="px-2 mt-3"
          rowKey="id"
          columns={printTableColumns}
          dataSource={printTableData}
          pagination={false}
          rowClassName={rowClassName}
        />
      </div>
    );
  }

  const triggerPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // const triggerDownload = () => {
  //   csvLinkRef.current.link.click();
  // };

  // Only trigger this API call when printing or exporting report
  // we are not using the data on the table for print & export since those are incomplete
  async function fetchPrintExportData(action) {
    var tempTotals = {
      basic_pay: 0,
      holiday_pay: 0,
      regular_ot_mins: 0,
      regular_ot: 0,
      restday_ot_mins: 0,
      restday_ot: 0,
      total_earnings: 0,
      sss_contribution: 0,
      philhealth_contribution: 0,
      pagibig_contribution: 0,
      late: 0,
      undertime_amount: 0,
      total_deduction: 0,
      total_payout: 0,
    };

    setPrintTableData([]);
    const response = await UsePostRequest("payrolls/get", filterConfig);
    if (response.data) {
      const res = response.data.map((row) => {
        // Get the totals
        tempTotals.basic_pay += Number(row.basic_pay);
        tempTotals.holiday_pay += Number(row.holiday_pay);
        tempTotals.regular_ot += Number(row.regular_ot);
        tempTotals.regular_ot_mins += Number(minsToHours(row.regular_ot_mins));
        tempTotals.restday_ot += Number(row.restday_ot);
        tempTotals.restday_ot_mins += Number(minsToHours(row.restday_ot_mins));
        tempTotals.total_earnings += Number(row.total_earnings);
        tempTotals.sss_contribution += Number(row.sss_contribution);
        tempTotals.philhealth_contribution += Number(
          row.philhealth_contribution
        );
        tempTotals.pagibig_contribution += Number(row.pagibig_contribution);
        tempTotals.late += Number(row.late);
        tempTotals.undertime_amount += Number(row.undertime_amount);
        tempTotals.total_deduction += Number(row.total_deduction);
        tempTotals.total_payout += Number(row.total_payout);

        var info = row;
        info.regular_ot_mins = minsToHours(row.regular_ot_mins);
        info.restday_ot_mins = minsToHours(row.restday_ot_mins);
        return info;
      });

      // Insert the totals as the last row in the print table data
      const tempTotalRow = {
        date_from: "",
        date_to: "",
        employee_name: "TOTAL",
        ...tempTotals,
      };

      setPrintTableData([...res, tempTotalRow]);
    }

    setTimeout(() => {
      if (action === "print") {
        triggerPrint();
      }
    }, 500);
  }

  React.useEffect(() => {
    if (generatePdf) {
      renderPdfGenerator();
    }
  }, [generatePdf]);

  // React.useEffect(() => {
  //   const searchTimeout = setTimeout(() => {
  //     setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
  //   }, 2000);

  //   return () => {
  //     clearTimeout(searchTimeout);
  //   };
  // }, [searchTerm]);

  React.useEffect(() => {
    setItemsToDelete([]);
    setShowDeleteMultiple(false);

    async function fetchTableData() {
      setIsFetching(true);
      setTableData([]);
      setVerticalTableData([]);
      const response = await UsePostRequest("payrolls/get_all", filterConfig);
      if (response.data) {
        setTableData(response.data);
        // CREATE KEY-VALUE-PAIR TABLE DATA FOR VERTICAL TABLE
        // create a new array of object containing header and value
        const tempTableData = response.data.map((row) =>
          Object.entries(row).map(([header, value]) => ({
            header,
            value,
            ...row,
          }))
        );
        // extract only the objects we need for the table
        const filteredDataSource = tempTableData
          .flat()
          .filter((record) =>
            tableColumns.some((column) => column.dataIndex === record.header)
          );
        setVerticalTableData(filteredDataSource);
      }
      setIsFetching(false);
    }

    async function fetchExcelExportData() {
      setPayrollExcelUrl();
      const response = await UseGetRequest(
        "payrolls/create_payroll_excel",
        filterConfig
      );
      if (response.data) {
        setPayrollExcelUrl(response.data.url);
      }
    }

    fetchTableData();
    fetchExcelExportData();
  }, [filterConfig]);

  // React.useEffect(() => {
  //   const searchTimeout = setTimeout(() => {
  //     setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
  //   }, 2000);

  //   return () => {
  //     clearTimeout(searchTimeout);
  //   };
  // }, [searchTerm]);

  // This is done on purpose so the print component updates as the print data changes
  // Otherwise, the print data will always be empty
  React.useEffect(() => {
    renderPrintTable();
  }, [printTableData]);

  return (
    <Navbar
      pageTitle="Payrolls"
      noButton
      actionDropdownButton={!window.$isEmployeeLogin}
      actionDropdownOptions={isAdminLogin?actionDropdownOptions:employeeActions}
      setSearchQuery={(e) => setSearchTerm(e)}
      onSearch={handleSearch}
      onClickButton={() => setShowAddModal(true)}
      isManager
      searchable={isAdminLogin}
      searchPlaceholder="employee"
      withDateRangePicker
      onDateChange={handleDateFilter}
    >
      {isAdminLogin && (
        <Row className="mt-4 d-flex justify-content-between">
          {showDeleteMultiple && (
            <Space className="">
              <Button
                danger
                onClick={(e) => confirmMultipleDelete(e)}
                className="me-2"
                disabled={!itemsToDelete || itemsToDelete.length === 0}
              >
                Delete Selected
              </Button>
              <Button
                onClick={() => {
                  setShowDeleteMultiple(false);
                  setItemsToDelete([]);
                }}
              >
                Cancel
              </Button>
            </Space>
          )}
        </Row>
      )}

      <Table
        className={isAdminLogin ? "" : "mt-4"}
        rowKey="id"
        columns={isPhoneScreen ? keyValuePairColumns : tableColumns}
        dataSource={isPhoneScreen ? verticalTableData : tableData}
        loading={isFetching || isLoading}
      />

      <Modal
        title="Review Payroll"
        centered
        open={showViewModal}
        footer={null}
        onCancel={() => {
          setShowViewModal(false);
          editForm.resetFields();
        }}
      >
        <Form className="mt-3 mx-3" form={editForm} name="editForm" layout="">
          <Form.Item name="employee_name" label="Name">
            <Input disabled />
          </Form.Item>
          <Form.Item name="date_from" label="Date From">
            <DatePicker disabled />
          </Form.Item>
          <Form.Item name="date_to" label="Date To">
            <DatePicker disabled />
          </Form.Item>
          <Form.Item name="total_payout" label="Total Payout">
            <Input disabled />
          </Form.Item>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Button
                danger
                onClick={() => setShowViewModal(false)}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type="text"
                danger
                onClick={() => {
                  confirmDelete(clickedRowData);
                }}
                icon={<DeleteOutlined />}
                disabled={!isAdminLogin}
              >
                Delete
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => {
                  window.open(
                    "/payrolls/payslip/" + clickedRowData.payroll_id,
                    "_blank"
                  );
                }}
              >
                View
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        maskClosable={false}
        title="Generate Payroll"
        centereds
        open={showAddModal}
        onCancel={() => setShowAddModal(false)}
        footer={false}
      >
        <Form
          form={form}
          className="mt-3 mx-3"
          name="validateOnly"
          layout=""
          onFinish={onFinish}
        >
          <Form.Item
            name="salary_type"
            label="Salary Type"
            rules={[{ required: true }]}
          >
            <Select options={salaryOptions} />
          </Form.Item>
          <Form.Item
            name="date_from"
            label="Date From"
            rules={[{ required: true }]}
          >
            <DatePicker placeholder="YYYY-MM-DD" />
          </Form.Item>
          <Form.Item
            name="date_to"
            label="Date To"
            rules={[{ required: true }]}
          >
            <DatePicker placeholder="YYYY-MM-DD" />
          </Form.Item>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Button
                className="me-2"
                danger
                onClick={() => setShowAddModal(false)}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <AddSubmitButton form={form} isLoading={isLoading} />
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        open={pdfGenerationInProgress}
        footer={null}
        onCancel={() => setPdfGenerationInProgress(false)}
      >
        <Row className="p-2 center">DOWNLOAD PDF</Row>
        <Row className="center">
          <Spin size="large" />
        </Row>
        <Row className="p-2 center">Generating...</Row>
      </Modal>

      <div className="printOnly">
        <div className="print-report-page" ref={componentRef}>
          {renderPrintTable()}
        </div>
      </div>
      <div>{renderPdfGenerator()}</div>

      {contextHolder}
    </Navbar>
  );
}
