import React, { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Button, Form, Input, Row, Card, Col, Select } from "antd";
import { usePostRequest } from "../../hooks/usePostRequest";
import { UseGetRequest } from "../../utils/api/ApiRequests";
import { refreshPage } from "../../utils/Common";

// const AddSubmitButton = ({ form, isLoading }) => {
//   const [submittable, setSubmittable] = useState(false);

//   // Watch all values
//   const values = Form.useWatch([], form);
//   React.useEffect(() => {
//     form
//       .validateFields({
//         validateOnly: true,
//       })
//       .then(
//         () => {
//           setSubmittable(true);
//         },
//         () => {
//           setSubmittable(false);
//         }
//       );
//   }, [values]);
//   return (
//     <Button
//       type="primary"
//       htmlType="submit"
//       disabled={!submittable}
//       loading={isLoading}
//     >
//       Submit
//     </Button>
//   );
// };

const rdoCodes = [
  {value: "001", label: "001"},
  {value: "002", label: "002"},
  {value: "003", label: "003"},
  {value: "004", label: "004"},
  {value: "005", label: "005"},
  {value: "006", label: "006"},
  {value: "007", label: "007"},
  {value: "008", label: "008"},
  {value: "009", label: "009"},
  {value: "010", label: "010"},
  {value: "011", label: "011"},
  {value: "012", label: "012"},
  {value: "013", label: "013"},
  {value: "014", label: "014"},
  {value: "015", label: "015"},
  {value: "016", label: "016"},
  {value: "17A", label: "17A"},
  {value: "17B", label: "17B"},
  {value: "018", label: "018"},
  {value: "019", label: "019"},
  {value: "020", label: "020"},
  {value: "21A", label: "21A"},
  {value: "21B", label: "21B"},
  {value: "21C", label: "21C"},
  {value: "022", label: "022"},
  {value: "23A", label: "23A"},
  {value: "23B", label: "23B"},
  {value: "024", label: "024"},
  {value: "25A", label: "25A"},
  {value: "25B", label: "25B"},
  {value: "026", label: "026"},
  {value: "027", label: "027"},
  {value: "028", label: "028"},
  {value: "029", label: "029"},
  {value: "030", label: "030"},
  {value: "031", label: "031"},
  {value: "032", label: "032"},
  {value: "033", label: "033"},
  {value: "034", label: "034"},
  {value: "035", label: "035"},
  {value: "036", label: "036"},
  {value: "037", label: "037"},
  {value: "038", label: "038"},
  {value: "039", label: "039"},
  {value: "040", label: "040"},
  {value: "041", label: "041"},
  {value: "042", label: "042"},
  {value: "043", label: "043"},
  {value: "044", label: "044"},
  {value: "045", label: "045"},
  {value: "046", label: "046"},
  {value: "047", label: "047"},
  {value: "048", label: "048"},
  {value: "049", label: "049"},
  {value: "050", label: "050"},
  {value: "051", label: "051"},
  {value: "052", label: "052"},
  {value: "53A", label: "53A"},
  {value: "53B", label: "53B"},
  {value: "54A", label: "54A"},
  {value: "54B", label: "54B"},
  {value: "055", label: "055"},
  {value: "056", label: "056"},
  {value: "057", label: "057"},
  {value: "058", label: "058"},
  {value: "059", label: "059"},
  {value: "060", label: "060"},
  {value: "061", label: "061"},
  {value: "062", label: "062"},
  {value: "063", label: "063"},
  {value: "064", label: "064"},
  {value: "065", label: "065"},
  {value: "066", label: "066"},
  {value: "067", label: "067"},
  {value: "068", label: "068"},
  {value: "069", label: "069"},
  {value: "070", label: "070"},
  {value: "071", label: "071"},
  {value: "072", label: "072"},
  {value: "073", label: "073"},
  {value: "074", label: "074"},
  {value: "075", label: "075"},
  {value: "076", label: "076"},
  {value: "077", label: "077"},
  {value: "078", label: "078"},
  {value: "079", label: "079"},
  {value: "080", label: "080"},
  {value: "081", label: "081"},
  {value: "082", label: "082"},
  {value: "083", label: "083"},
  {value: "084", label: "084"},
  {value: "085", label: "085"},
  {value: "086", label: "086"},
  {value: "087", label: "087"},
  {value: "089", label: "089"},
  {value: "090", label: "090"},
  {value: "091", label: "091"},
  {value: "092", label: "092"},
  {value: "93A", label: "93A"},
  {value: "93B", label: "93B"},
  {value: "094", label: "094"},
  {value: "095", label: "095"},
  {value: "096", label: "096"},
  {value: "097", label: "097"},
  {value: "098", label: "098"},
  {value: "099", label: "099"},
  {value: "100", label: "100"},
  {value: "101", label: "101"},
  {value: "102", label: "102"},
  {value: "103", label: "103"},
  {value: "104", label: "104"},
  {value: "105", label: "105"},
  {value: "106", label: "106"},
  {value: "107", label: "107"},
  {value: "108", label: "108"},
  {value: "109", label: "109"},
  {value: "110", label: "110"},
  {value: "111", label: "111"},
  {value: "112", label: "112"},
  {value: "113A", label: "113A"},
  {value: "113B", label: "113B"},
  {value: "114", label: "114"},
  {value: "115", label: "115"},  
]


export default function BusinessUnit() {
  const [form] = Form.useForm();
  const [enableEdit, setEnableEdit] = useState(false);
  const { callPost, isLoading } = usePostRequest(() => {
    setEnableEdit(false);
    setLogoFile(null);
    fetchTableData();
  });
  const [logoFile, setLogoFile] = useState(null);

  const onFinish = (formInputs) => {
    formInputs.logo = formInputs.logo[0];
    Object.keys(formInputs).forEach((key) => {
      if (!formInputs[key] || formInputs[key]===undefined || formInputs[key]==="undefined") {
        formInputs[key] = ""
      }
    })
    var values = Object.entries(formInputs).map(([key, value]) => ({ key, value }));
    callPost("business_units/add", values, true);
  };

  async function fetchTableData() {
    setLogoFile(null)
    const response = await UseGetRequest("business_units/get", {business_unit_id: "1"});
    if (response.data) {
      const res = response.data.data
      setLogoFile(res[0].logo)
      form.setFieldsValue(res[0])
    }
  }

  React.useEffect(() => {
    fetchTableData();
  }, []);

  return (
    <Navbar pageTitle="Company" noButton isManager>
      <Card className="mt-2 bir-form">
        <Form
          className="mt-3 mx-3"
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={onFinish}
        >
          <Row>
            <Col xs={12} lg={8}>
              <Form.Item
                name="name"
                label="Company Name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled={!enableEdit} />
              </Form.Item>
            </Col>
            <Col xs={12} lg={8}>
              <Form.Item
                name="tin"
                label="TIN"
                rules={[
                  {
                    required: true,
                  },
                  // {
                  //   pattern: /^\d{3}-\d{3}-\d{3}-\d{3}$/,
                  //   message: 'Input must be in the format 123-456-789-000',
                  // },
                ]}
              >
                <Input
                  placeholder="Ex: 500-455-919-555"
                  disabled={!enableEdit}
                />
              </Form.Item>
            </Col>
            <Col xs={12} lg={8}>
              <Form.Item
                name="rdo_code"
                label="RDO Code"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select disabled={!enableEdit} options={rdoCodes} showSearch filterOption={(input, option) => (option.label.toLowerCase().includes(input.toLowerCase()))}/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={16}>
              <Form.Item
                name="bir_registered_address"
                label="BIR Registered Address"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled={!enableEdit} />
              </Form.Item>
            </Col>
            <Col xs={12} lg={8}>
              <Form.Item
                name="zip_code"
                label="ZIP Code"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input type="number" onWheel={(e)=>e.target.blur()} disabled={!enableEdit} />
              </Form.Item>
            </Col>
            <Col xs={12} lg={8}>
              <Form.Item
                name="contact_no"
                label="Contact Number"
                // rules={[
                //   {
                //     required: false,
                //   },
                //   {
                //     pattern: /^\d{11}$/,
                //     message: 'Contact number must contain 11 digits',
                //   },
                // ]}
              >
                <Input placeholder="Ex: 09123456789" disabled={!enableEdit} />
              </Form.Item>
            </Col>
            <Col xs={12} lg={8}>
              <Form.Item
                name="withholding_agents"
                label="Category of Withholding Agents"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  disabled={!enableEdit}
                  options={[
                    { value: "private", label: "Private" },
                    { value: "government", label: "Government" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={12} lg={8}>
              <Form.Item
                name="email_address"
                label="Email Address"
                // rules={[
                //   {
                //     required: false,
                //   },
                //   {
                //     type: 'email',
                //     message: 'Please enter a valid email address',
                //   },
                // ]}
              >
                <Input
                  placeholder="Ex: janedoe@email.com"
                  disabled={!enableEdit}
                />
              </Form.Item>
            </Col>
            <Col xs={12} lg={8}>
              <Form.Item
                name="sss_no"
                label="SSS Number"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input type="text" onWheel={(e)=>e.target.blur()} disabled={!enableEdit} />
              </Form.Item>
            </Col>
            <Col xs={12} lg={8}>
              <Form.Item
                name="phic_no"
                label="PHIC Number"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input type="text" onWheel={(e)=>e.target.blur()} disabled={!enableEdit} />
              </Form.Item>
            </Col>
            <Col xs={12} lg={8}>
              <Form.Item
                name="hdmf_no"
                label="HDMF Number"
                // rules={[
                //   {
                //     required: true,
                //   },
                // ]}
              >
                <Input type="text" onWheel={(e)=>e.target.blur()} disabled={!enableEdit} />
              </Form.Item>
            </Col>
            <Col xs={12} lg={8}>
              <Form.Item
                name="logo"
                label="Company Logo"
                valuePropName="files"
              >
                <Input type="file" accept=".png, .jpg, .jpeg" disabled={!enableEdit} />
              </Form.Item>
              <img src={logoFile} alt="" className="w-100"/>
            </Col>
            
          </Row>

          {/* <Form.Item>
            <Row className="d-flex justify-content-end">
              <Row>
                {!enableEdit && (
                  <Button
                    type="primary"
                    className="me-2"
                    disabled={isLoading}
                    onClick={() => setEnableEdit(true)}
                  >
                    {" "}
                    Edit
                  </Button>
                )}
                {enableEdit && (
                  <>
                  <Button className="me-2" onClick={() => {refreshPage()}}>
                    Cancel Edit
                  </Button>
                  <AddSubmitButton form={form} isLoading={isLoading} />
                  </>
                )}
                
              </Row>
            </Row>
          </Form.Item> */}
        </Form>
      </Card>
    </Navbar>
  );
}
