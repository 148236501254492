import axios from "axios"
import { getUser, getToken, getLocalData, refreshPage } from "../Common"
import qs from "qs"

const postCall = async (url, data, config) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + url,
      qs.stringify(data),
      config
    )
    return response.data
  } catch (error) {
    throw error.response
  }
}

export const getCall = (url, config) => {
  return axios.get(process.env.REACT_APP_BASE_URL + url, config)
}

export const removeUserSession = () => {
  logout()
  localStorage.removeItem("token")
  localStorage.removeItem("user")
  localStorage.removeItem("employee_id")
  localStorage.removeItem("role_id")
  localStorage.removeItem("fullname")
  localStorage.removeItem("token_expiry")
}

export const login = async (values) => {
  try {
    const response = await postCall(
      "login",
      { ...values },
      {
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )
    return { data: response }
  } catch (error) {
    return { error: error.data }
  }
}

export const logout = async () => {
  try {
    const response = await postCall(
      "logout",
      { requester: getUser(), token: getToken() },
      {
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error }
  }
}

export const checkTokenExpiry = () => {
  const token_expiry = getLocalData("token_expiry")
  if (token_expiry) {
    const currentDateTime = new Date()
    const tokenExpiry = new Date(token_expiry)

    if (tokenExpiry && currentDateTime > tokenExpiry) {
      removeUserSession()
      setTimeout(() => {
        refreshPage()
      }, 1000)
    }
  }
}

export const uploadTimesheet = async (values) => {
  try {
    const response = await postCall(
      "timesheets/upload_timesheet",
      { requester: getUser(), token: getToken(), ...values },
      {
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )
    return { data: response }
  } catch (error) {
    return { error: error.data }
  }
}
