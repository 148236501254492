import React, { useState } from "react"
import Navbar from "../../components/Navbar/Navbar"
import { Button, Form, Modal, Table, Row, Col, DatePicker, Select } from "antd"
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons"
import { usePostRequest } from "../../hooks/usePostRequest"
import { UsePostRequest } from "../../utils/api/ApiRequests"
import moment from "moment"
import dayjs from "dayjs"
import {
  GetAllEmployees,
  GetAllShifts,
} from "../../utils/api/DropdownFiltersApi"
import {
  capitalizeEachWord,
  getEmployeeId,
  getRoleName,
} from "../../utils/Common"

const AddSubmitButton = ({ form, isLoading }) => {
  const [submittable, setSubmittable] = useState(false)
  const values = Form.useWatch([], form)
  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true)
        },
        () => {
          setSubmittable(false)
        }
      )
  }, [values])

  return (
    <Button
      type="primary"
      htmlType="submit"
      disabled={!submittable}
      loading={isLoading}
    >
      Submit
    </Button>
  )
}

export default function Schedule() {
  const [form] = Form.useForm()
  const employeeOptions = GetAllEmployees()
  const shiftOptions = GetAllShifts()
  const dateFormat = "YYYY-MM-DD"

  const { callPost, isLoading } = usePostRequest(() => {
    setShowAddModal(false)
    setShowEditModal(false)
    setShowViewModal(false)
    setFilterConfig((prev) => ({ ...prev, is_updated: !prev.is_updated }))
  })
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showViewModal, setShowViewModal] = useState(false)
  const [clickedRowData, setClickedRowData] = useState({})
  // const [initialValues, setInitialValues] = useState({});
  const [tableData, setTableData] = useState([])
  const [modal, contextHolder] = Modal.useModal()

  const [filterConfig, setFilterConfig] = useState({
    is_updated: false,
    date_from: dayjs().format("YYYY-MM-DD"),
    date_to: dayjs().add(6, "days").format("YYYY-MM-DD"),
    employee_id: window.$isEmployeeLogin ? getEmployeeId() : "",
  })
  const [searchTerm, setSearchTerm] = useState(null)

  const [isFetching, setIsFetching] = useState(false)
  const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ]

  const dateColumns = days.map((day) => {
    return {
      title: capitalizeEachWord(day),
      dataIndex: `${day}_date`,
      key: `${day}_date`,
      render: (data, record) => (
        <div onClick={() => handleRowClick(record)}>
          <span>{moment(data).format("MMM DD, YYYY")}</span>
          <br />
          <span>{record[`${day}_shift_name`]}</span>
        </div>
      ),
    }
  })

  const tableColumns = [
    {
      title: "Employee",
      dataIndex: "full_name",
      key: "full_name",
    },
    ...dateColumns,
  ]

  const onFinish = (formInputs) => {
    const payload = {
      ...formInputs,
      date_from: formInputs.date_from.format(dateFormat),
      date_to: formInputs.date_to.format(dateFormat),
      monday_date: formInputs.monday_date.format(dateFormat),
      tuesday_date: formInputs.tuesday_date.format(dateFormat),
      wednesday_date: formInputs.wednesday_date.format(dateFormat),
      thursday_date: formInputs.thursday_date.format(dateFormat),
      friday_date: formInputs.friday_date.format(dateFormat),
      saturday_date: formInputs.saturday_date.format(dateFormat),
      sunday_date: formInputs.sunday_date.format(dateFormat),
    }
    callPost("schedules/add", payload, false)
  }

  const onEdit = (formInputs) => {
    const payload = {
      ...formInputs,
      schedule_id: clickedRowData.id,
      date_from: formInputs.date_from.format(dateFormat),
      date_to: formInputs.date_to.format(dateFormat),
      monday_date: formInputs.monday_date.format(dateFormat),
      tuesday_date: formInputs.tuesday_date.format(dateFormat),
      wednesday_date: formInputs.wednesday_date.format(dateFormat),
      thursday_date: formInputs.thursday_date.format(dateFormat),
      friday_date: formInputs.friday_date.format(dateFormat),
      saturday_date: formInputs.saturday_date.format(dateFormat),
      sunday_date: formInputs.sunday_date.format(dateFormat),
    }
    callPost(`schedules/update`, payload, false)
  }

  const confirmDelete = (attendance) => {
    modal.confirm({
      title: `Are you sure you want to delete this schedule?`,
      icon: <ExclamationCircleOutlined />,
      content: "You cannot undo this action.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: () => handleDelete(attendance.id),
      okType: "danger",
    })
  }

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setFilterConfig((prev) => ({ ...prev, term: searchTerm }))
    }
  }

  function handleDelete(passedId) {
    callPost("schedules/delete", { schedule_id: passedId }, false)
  }

  function handleChangeDateFrom(date) {
    const tempFormValues = {
      date_to: dayjs(date).add(6, "days"), // sunday
      monday_date: dayjs(date),
      tuesday_date: dayjs(date).add(1, "days"),
      wednesday_date: dayjs(date).add(2, "days"),
      thursday_date: dayjs(date).add(3, "days"),
      friday_date: dayjs(date).add(4, "days"),
      saturday_date: dayjs(date).add(5, "days"),
      sunday_date: dayjs(date).add(6, "days"),
    }
    form.setFieldsValue(tempFormValues)
  }

  const handleRowClick = (rowData) => {
    setClickedRowData(rowData)
    const formattedValues = { ...rowData }
    formattedValues.date_from = rowData.date_from
      ? dayjs(rowData.date_from, dateFormat)
      : ""
    formattedValues.date_to = rowData.date_to
      ? dayjs(rowData.date_to, dateFormat)
      : ""
    days.forEach((day) => {
      formattedValues[`${day}_date`] = rowData[`${day}_date`]
        ? dayjs(rowData[`${day}_date`], dateFormat)
        : ""
    })
    form.setFieldsValue(formattedValues)
    setShowViewModal(true)
  }

  const rowProps = (record) => ({
    onClick: () => handleRowClick(record),
  })

  const [rangePickerValue, setRangePickerValue] = useState([null, null])

  const handleDateFilter = (e) => {
    if (e) {
      const startDate = e
      const endDate = dayjs(startDate).add(6, "days")
      setRangePickerValue([startDate, endDate])

      // If you need to update other states or perform actions
      setFilterConfig((prev) => ({
        ...prev,
        date_from: startDate.format(dateFormat),
        date_to: endDate.format(dateFormat),
      }))
    }
  }

  React.useEffect(() => {
    async function fetchTableData() {
      setIsFetching(true)
      setTableData([])
      const response = await UsePostRequest("schedules/get", filterConfig)
      if (response.data) {
        setTableData(response.data)
      }
      setIsFetching(false)
    }

    fetchTableData()
  }, [filterConfig])

  return (
    <Navbar
      pageTitle="Scheduler"
      pageButtonTitle="Add Schedule"
      searchPlaceholder="employee"
      setSearchQuery={(e) => setSearchTerm(e)}
      onSearch={handleSearch}
      onClickButton={() => setShowAddModal(true)}
      isManager
      searchable
      onSchedulerDateChange={handleDateFilter}
      schedulerDateRangePicker
      dateValues={rangePickerValue}
      userRole={getRoleName()}
    >
      <Table
        className="mt-4"
        rowKey="id"
        onRow={(record) => ({
          ...rowProps(record),
          style: { cursor: "pointer" },
        })}
        columns={tableColumns}
        dataSource={tableData}
        loading={isFetching || isLoading}
      />

      <Modal
        maskClosable={false}
        title={`${showAddModal ? "Add" : "Edit"} Schedule`}
        centered
        open={showAddModal || showEditModal}
        footer={null}
        onCancel={() => {
          setShowAddModal(false)
          setShowEditModal(false)
          form.resetFields()
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={showAddModal ? onFinish : onEdit}
        >
          <Row>
            <Col xs={24} lg={10}>
              <Form.Item
                name="employee_id"
                label="Employee"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  options={employeeOptions.slice(1)}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={7}>
              <Form.Item
                name="date_from"
                label="Date From"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker
                  disabledDate={(day) => day.day() !== 1}
                  placeholder="YYYY-MM-DD"
                  onChange={handleChangeDateFrom}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={7}>
              <Form.Item
                name="date_to"
                label="Date To"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker placeholder="YYYY-MM-DD" disabled />
              </Form.Item>
            </Col>
          </Row>
          {days.map((day) => (
            <Row className="">
              <Col xs={10} className="ps-5">
                {capitalizeEachWord(day)}
              </Col>
              <Col xs={7}>
                <Form.Item
                  name={`${day}_date`}
                  label=""
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePicker placeholder="YYYY-MM-DD" disabled />
                </Form.Item>
              </Col>
              <Col xs={7}>
                <Form.Item
                  name={`${day}_shift`}
                  label=""
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    placeholder="Select shift"
                    options={shiftOptions.slice(1)}
                  />
                </Form.Item>
              </Col>
            </Row>
          ))}

          <Form.Item>
            <Row className="d-flex justify-content-end mt-2">
              <Row>
                <Button
                  className="me-2"
                  danger
                  onClick={() => {
                    form.resetFields()
                    setShowAddModal(false)
                    setShowEditModal(false)
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <AddSubmitButton form={form} isLoading={isLoading} />
              </Row>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Review Schedule"
        centered
        open={showViewModal}
        footer={null}
        onCancel={() => {
          setShowViewModal(false)
          form.resetFields()
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="editForm"
          layout="vertical"
        >
          <Row>
            <Col xs={24} lg={10}>
              <Form.Item name="employee_id" label="Employee">
                <Select options={employeeOptions.slice(1)} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} lg={7}>
              <Form.Item name="date_from" label="Date From">
                <DatePicker disabled />
              </Form.Item>
            </Col>
            <Col xs={24} lg={7}>
              <Form.Item name="date_to" label="Date To">
                <DatePicker placeholder="YYYY-MM-DD" disabled />
              </Form.Item>
            </Col>
          </Row>
          {days.map((day) => (
            <Row className="">
              <Col xs={10} className="ps-5">
                {capitalizeEachWord(day)}
              </Col>
              <Col xs={7}>
                <Form.Item name={`${day}_date`} label="">
                  <DatePicker placeholder="YYYY-MM-DD" disabled />
                </Form.Item>
              </Col>
              <Col xs={7}>
                <Form.Item name={`${day}_shift`} label="">
                  <Select
                    placeholder="Select shift"
                    options={shiftOptions.slice(1)}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
          ))}

          <Form.Item>
            <Row className="d-flex justify-content-end mt-2">
              <Button
                danger
                onClick={() => {
                  form.resetFields()
                  setShowViewModal(false)
                }}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type="text"
                onClick={() => {
                  setShowViewModal(false)
                  setShowEditModal(true)
                }}
                icon={<EditOutlined />}
              >
                Edit
              </Button>
              <Button
                type="text"
                danger
                onClick={() => {
                  confirmDelete(clickedRowData)
                }}
                icon={<DeleteOutlined />}
              >
                Delete
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      {contextHolder}
    </Navbar>
  )
}
