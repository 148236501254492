import React, { useState, useEffect } from "react";
import { UseGetRequest, UsePostRequest } from "./ApiRequests";
import { capitalizeEachWord, getEmployeeId } from "../Common";

export const GetAllBranches = () => {
  const [data, setData] = useState([]);
  const defaultValue = {
    id: "",
    value: "",
    label: "All Branches",
    name: "All Branches",
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await UseGetRequest("branches/get", {});
        if (response.data) {
          const res = response.data.data.map((row) => {
            return {
              id: row.id,
              value: row.id,
              name: row.name,
              label: row.name,
            };
          });
          setData([defaultValue, ...res]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  return data;
};

export const GetAllEmploymentStatus = () => {
  const [data, setData] = useState([]);
  const defaultValue = {
    id: "",
    value: "",
    label: "All Statuses",
    name: "All Statuses",
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await UseGetRequest("employment_statuses/get", {});
        if (response.data) {
          const res = response.data.data.map((row) => {
            return {
              id: row.id,
              value: row.id,
              name: row.name,
              label: row.name,
            };
          });
          setData([defaultValue, ...res]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  return data;
};

export const GetAllEmployeeStatus = () => {
  const [data, setData] = useState([]);
  const defaultValue = {
    id: "",
    value: "",
    label: "All Statuses",
    name: "All Statuses",
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await UseGetRequest("employee_statuses/get", {});
        if (response.data) {
          const res = response.data.data.map((row) => {
            return {
              id: row.id,
              value: row.id,
              name: row.name,
              label: row.name,
            };
          });
          setData([defaultValue, ...res]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  return data;
};

export const GetAllPositions = () => {
  const [data, setData] = useState([]);
  const defaultValue = {
    id: "",
    value: "",
    label: "All Positions",
    name: "All Positions",
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await UseGetRequest("positions/get", {});
        if (response.data) {
          const res = response.data.data.map((row) => {
            return {
              id: row.id,
              value: row.id,
              name: row.name,
              label: row.name,
            };
          });
          setData([defaultValue, ...res]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  return data;
};

export const GetAllBusinessUnits = () => {
  const [data, setData] = useState([]);
  const defaultValue = [
    {
      id: "",
      value: "",
      label: "All Business Units",
      name: "All Business Units",
    },
    // {
    //   id: "1",
    //   value: "1",
    //   label: "Ananta Farms",
    //   name: "Ananta Farms",
    // },
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await UseGetRequest("business_units/get", {});
        if (response.data) {
          const res = response.data.data.map((row) => {
            return {
              id: row.id,
              value: row.id,
              name: row.name,
              label: row.name,
            };
          });
          setData([defaultValue, ...res]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  return data;
};

// export const GetAllProjects = () => {
//   const [data, setData] = useState([]);
//     const defaultValue = {
//         id: '',
//         value: '',
//         label: 'All Projects',
//         name: 'All Projects'
//     };

//   useEffect(() => {
//     async function fetchData() {
//       try {
//         const response = await UseGetRequest("projects/get", {});
//         if (response.data) {
//           const res = response.data.data.map((row) => {
//             return {
//               id: row.id,
//               value: row.id,
//               name: row.name,
//               label: row.name,
//             };
//           });
//           setData([defaultValue, ...res]);
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     }
//     fetchData();
//   }, []);

//   return data;
// };

export const GetAllDepartments = () => {
  const [data, setData] = useState([]);
  const defaultValue = {
    id: "",
    value: "",
    label: "All Departments",
    name: "All Departments",
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await UseGetRequest("departments/get", {});
        if (response.data) {
          const res = response.data.data.map((row) => {
            return {
              id: row.id,
              value: row.id,
              name: row.name,
              label: row.name,
            };
          });
          setData([defaultValue, ...res]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  return data;
};

export const GetAllEmployees = () => {
  const [data, setData] = useState([]);
  const defaultValue = {
    id: "",
    value: "",
    label: "All Employees",
    name: "All Employees",
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await UsePostRequest("employees/get_all", {
          employee_id: window.$isEmployeeLogin ? getEmployeeId() : ""
        });
        if (response.data) {
          const res = response.data.map((row) => {
            var tempName = `${row.first_name} ${row.middle_name} ${row.last_name}`;
            return {
              id: row.id,
              value: row.id,
              name: capitalizeEachWord(tempName),
              label: capitalizeEachWord(tempName),
              dtr_id: row.dtr_id,
              shift_time_in: row.shift_time_in,
              shift_time_out: row.shift_time_out,
              salary_type: row.salary_type,
              shift_name: row.shift_name,
              current_shift_id: row.current_shift_id,
            };
          });
          setData([defaultValue, ...res]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  return data;
};

export const GetAllShifts = () => {
  const [data, setData] = useState([]);
    const defaultValue = {
        id: '',
        value: '',
        label: 'All Shifts',
        name: 'All Shifts'
    };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await UseGetRequest("shifts/get", {});
        if (response.data) {
          const res = response.data.data.map((row) => {
            return {
              id: row.id,
              value: row.id,
              name: row.name,
              label: row.name,
            };
          });
          setData([defaultValue, ...res]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  return data;
};

export const GetAllRoles = () => {
  const [data, setData] = useState([]);
  const defaultValue = {
    id: "",
    value: "",
    label: "All Roles",
    name: "All Roles",
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await UseGetRequest("roles/get", {});
        if (response.data) {
          const res = response.data.data.map((row) => {
            return {
              id: row.id,
              value: row.id,
              name: row.name,
              label: row.name,
            };
          });
          setData([defaultValue, ...res]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  return data;
};

export const GetAllSalaryTypes = () => {
  const salaryTypes = [
    { value: "daily6", label: "Daily" },
    { value: "monthly26", label: "Monthly (26)" },
  ];

  return salaryTypes;
};

export const GetAllLeaveTypes = () => {
  const [data, setData] = useState([]);
  const defaultValue = {
    id: "",
    value: "",
    label: "All Leave Types",
    name: "All Leave Types",
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await UseGetRequest("leave_types/get", {});
        if (response.data) {
          const res = response.data.data.map((row) => {
            return {
              id: row.id,
              value: row.id,
              name: row.description,
              label: row.description,
            };
          });
          setData([defaultValue, ...res]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  return data;
};
