import React, { useState, useRef } from "react";
import { Row, Col, Typography, Divider } from "antd";
import {
  extractAmount,
  formatAmount,
  formatDate,
  getSum,
} from "../../utils/Common";
import logo from "../../assets/images/logo.png";
import { UsePostRequest } from "../../utils/api/ApiRequests";
import moment from "moment";
const { Title } = Typography;

// This is the payslip lang but the PDF version to be downloaded.
// Will find way to use the Payslip file but for now this one should be separated lang sa
// due to the ways payroll_ids are passed from each files

const PayslipPdf = (props) => {
  const dateToday = new Date();
  // const location = useLocation();
  const componentRef = useRef();
  const payrollId = props.payroll_id;
  const [payrollData, setPayrollData] = useState({});
  // const [signature, setSignature] = useState(null);
  const [earningValues, setEarningValues] = useState([]);
  const [deductionValues, setDeductionValues] = useState([]);
  const earningLabels = [
    "Basic Pay",
    "Regular Holiday Pay",
    "Special Holiday Pay",
    "Communication Allowance",
    "Daily Allowance",
    "Transpo Allowance",
    "Food Allowance",
    "Uniform Allowance",
    "Grocery Allowance",
    "Entertainment Allowance",
    "Medicine Allowance",
    "Rice Allowance",
    "Ops Allowance",
    "Regular OT Pay",
    "Restday OT Pay",
    "Leave Pay",
    "Thirteenth Month",
    "Unused Sick Leave",
  ];
  const deductionLabels = [
    "PhilHealth Contribution",
    "PAGIBIG Contribution",
    "PAGIBIG Loan",
    "SSS Contribution",
    "SSS Calamity Loan",
    "SSS Salary Loan",
    // "Calamity Loan",
    "Company Loan",
    "Insurance",
    // "Phone Loan",
    // "Laptop Loan",
    // "Cash Advance",
    "Withholding Tax",
    "HMO",
    "Late",
    "Undertime",
    "Salary Adjustment",
  ];

  function formatDateRange(dateFrom, dateTo) {
    if (moment(dateFrom).format("YYYY") === moment(dateTo).format("YYYY")) {
      return `${moment(dateFrom).format("MMMM DD")} - ${moment(dateTo).format(
        "MMMM DD, YYYY"
      )}`;
    } else {
      return `${moment(dateFrom).format("MMMM DD, YYYY")} - ${moment(
        dateTo
      ).format("MMMM DD, YYYY")}`;
    }
  }

  React.useEffect(() => {
    props.setBulkPrintRef(componentRef.current);
  }, []);

  React.useEffect(() => {
    async function fetchPayrollData() {
      const response = await UsePostRequest("payrolls/get", {
        payroll_id: payrollId,
      });
      if (response.data) {
        const payroll = response.data[0];
        const tempEarnings = [];
        const tempdDeductions = [];
        var info = payroll;
        info.id = payroll.id;
        info.salary = Number(payroll.basic_pay);
        info.date_from = payroll.date_from;
        info.date_to = payroll.date_to;
        info.fullName = `${payroll.employee_name.split(",")[1]} ${
          payroll.employee_name.split(",")[0]
        }`;

        // Store all earnings in the earningValues state value
        tempEarnings.push(
          payroll.basic_pay,
          payroll.regular_holiday_pay,
          payroll.special_holiday_pay,
          payroll.comm_allowance,
          payroll.daily_allowance,
          payroll.transpo_allowance,
          payroll.food_allowance,
          payroll.uniform_allowance,
          payroll.grocery_allowance,
          payroll.entertainment_allowance,
          payroll.medicine_allowance,
          payroll.rice_allowance,
          payroll.ops_allowance,
          payroll.regular_ot_pay,
          payroll.restday_ot_pay,
          payroll.leave_pay
        );

        // Include thirteenth month to earnings if it was set to true during payroll generation
        if (payroll.is_thirteenth_included === "1") {
          tempEarnings.push(payroll.thirteenth_month);
        }
        // Include sickleave conversion to earnings if it was set to true during payroll generation
        if (payroll.is_sickleave_included === "1") {
          tempEarnings.push(payroll.unused_sick_leave);
        }
        // Store all deductions in the deductionValues state value
        tempdDeductions.push(
          payroll.philhealth,
          payroll.hdmf,
          payroll.pagibig_loan,
          payroll.sss,
          payroll.sss_calamity_loan,
          payroll.sss_salary_loan,
          // payroll.calamity_loan,
          payroll.company_loan,
          payroll.insurance_amount,
          // payroll.phone_loan,
          // payroll.laptop_loan,
          // payroll.cash_advance,
          payroll.wth_tax,
          payroll.hmo_amount,
          payroll.late,
          payroll.undertime_amount,
          payroll.salary_adj
        );

        info.total_earnings = getSum(tempEarnings);
        setEarningValues(tempEarnings);
        info.total_deductions = getSum(tempdDeductions);
        setDeductionValues(tempdDeductions);
        info.total_net_pay = info.total_earnings - info.total_deductions;
        setPayrollData(info);
      }
    }

    fetchPayrollData();
  }, []);

  return (
    <div ref={componentRef} className={`m-5 payslip-container ${props.hide}`}>
      <div className="payslip-container payslip-pdf m-2">
        <div className="pdf-confidential-mark">CONFIDENTIAL</div>
        <Row className="d-flex justify-content-end">
          <Title level={2}>PAYSLIP</Title>
        </Row>
        <Row className="d-flex justify-content-end">
          Date Printed: {formatDate(dateToday)}
        </Row>
        <Row>
          <img src={logo} className="payslip-logo" alt="" />
        </Row>
        <Row>
          <Title level={5}>{window.$company_name}</Title>
        </Row>
        <Row>{window.$address_line1}</Row>
        <Row>{window.$address_line2}</Row>

        {/* Employee details */}
        <Row>
          <table className="table table-bordered mt-3">
            <thead>
              <tr className="">
                <th colSpan={2}>Employee Information</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="bold">Name</td>
                <td>{payrollData.employee_name}</td>
              </tr>
              <tr>
                <td className="bold">Address</td>
                <td>{payrollData.address}</td>
              </tr>
              <tr>
                <td className="bold">Contact Number</td>
                <td>{payrollData.contact_no}</td>
              </tr>
            </tbody>
          </table>
        </Row>

        <Row className="mt-3">
          For the month of:{" "}
          {formatDateRange(payrollData.date_from, payrollData.date_to)}
        </Row>
        <Row>
          <Col xs={24} md={12} lg={12} className="mt-3 pe-2">
            <table className="table table-bordered">
              <thead>
                <tr className="">
                  <th>Earnings</th>
                  <th className="d-flex justify-content-end">Amount</th>
                </tr>
              </thead>

              <tbody>
                {earningValues.map((val, index) => {
                  if (val && extractAmount(val) > 0) {
                    return (
                      <tr key={index}>
                        <td>{earningLabels[index]}</td>
                        <td className="d-flex justify-content-end">
                          {formatAmount(val)}
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </Col>
          <Col xs={24} md={12} lg={12} className="mt-3 ps-2">
            <table className="table table-bordered">
              <thead>
                <tr className="">
                  <th>Deductions</th>
                  <th className="d-flex justify-content-end">Amount</th>
                </tr>
              </thead>

              <tbody>
                {deductionValues.map((val, index) => {
                  if (val && extractAmount(val) > 0) {
                    return (
                      <tr>
                        <td>{deductionLabels[index]}</td>
                        <td className="d-flex justify-content-end">
                          {formatAmount(val)}
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </Col>
        </Row>
        <Row className="mt-1">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>Gross Earnings</td>
                <td className="d-flex justify-content-end">
                  {formatAmount(payrollData.total_earnings)}
                </td>
                <td>Total Deductions</td>
                <td className="d-flex justify-content-end">
                  {formatAmount(payrollData.total_deductions)}
                </td>
              </tr>
              <tr>
                <td colSpan={2}></td>
                <td className="bold">Net Salary</td>
                <td className="d-flex justify-content-end bold">
                  {formatAmount(payrollData.total_net_pay)}
                </td>
              </tr>
            </tbody>
          </table>
        </Row>

        <Row className="mt-5 d-flex justify-content-between">
          <Col xs={11} className="mt-4">
            <Row className="d-flex justify-content-center no-margin-bottom uppercase">
              {window.$company_ceo}
            </Row>
            <Divider />
            <Row className="d-flex justify-content-center">
              Employer's Signature
            </Row>
          </Col>
          <Col xs={11} className="mt-4">
            {/* {signature && signature.trim() !== "" ? (
                  <Row className="d-flex justify-content-center no-margin-bottom">
                    {<img src={signature} alt="" className="signature" />}
                  </Row>
                ) : (
                  <Row className="mt-5"></Row>
                )} */}
            <Row className="d-flex justify-content-center no-margin-bottom">
              {payrollData.fullName ? payrollData.fullName.toUpperCase() : ""}
            </Row>
            <Divider />
            <Row className="d-flex justify-content-center">
              Employee's Signature
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PayslipPdf;
