import React, { useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import Navbar from "../../components/Navbar/Navbar"
import {
  Table,
  Space,
  Select,
  Button,
  Modal,
  Row,
  Col,
  Form,
  DatePicker,
  Input,
  Divider,
  Typography,
  InputNumber,
  Dropdown,
  Menu,
  Upload,
} from "antd"
import {
  DeleteOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons"
import dayjs from "dayjs"
import moment from "moment"
import AvatarEditor from "react-avatar-editor"
import "quill/dist/quill.snow.css"
import { UsePostRequest } from "../../utils/api/ApiRequests"
import {
  GetAllBusinessUnits,
  GetAllDepartments,
  GetAllEmployees,
  GetAllEmploymentStatus,
  GetAllShifts,
  GetAllPositions,
  GetAllSalaryTypes,
} from "../../utils/api/DropdownFiltersApi"
import { usePostRequest } from "../../hooks/usePostRequest"
import {
  capitalizeEachWord,
  getIsAdminLogin,
  getEmployeeId,
  refreshPage,
  getRoleName,
} from "../../utils/Common"
import toast from "react-hot-toast"
import Compressor from "compressorjs"

const AddSubmitButton = ({ form, isLoading }) => {
  const [submittable, setSubmittable] = useState(false)
  const values = Form.useWatch([], form)

  React.useEffect(() => {
    let validationTimeout

    if (validationTimeout) {
      clearTimeout(validationTimeout)
    }

    validationTimeout = setTimeout(() => {
      form
        .validateFields({ validateOnly: true })
        .then(() => setSubmittable(true))
        .catch(() => setSubmittable(false))
    }, 300)

    return () => {
      if (validationTimeout) {
        clearTimeout(validationTimeout)
      }
    }
  }, [values])

  return (
    <Button
      type="primary"
      htmlType="submit"
      disabled={!submittable}
      loading={isLoading}
    >
      Submit
    </Button>
  )
}

const { Title } = Typography

export default function Employee() {
  const [form] = Form.useForm()
  const [pictureForm] = Form.useForm()
  const [statusForm] = Form.useForm()
  const [filesForm] = Form.useForm()
  const navigate = useNavigate()
  const editorRef = useRef()
  const isAdminLogin = getIsAdminLogin()
  const role = getRoleName()
  const employeeId = getEmployeeId()
  const [tableData, setTableData] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showContractModal, setShowContractModal] = useState(false)
  const [showAllowanceModal, setShowAllowanceModal] = useState(false)
  const [showUploadProfile, setShowUploadProfile] = useState(false)
  const [photoList, setPhotoList] = useState([])
  const [formAction, setFormAction] = useState("")
  const [clickedRowId, setClickedRowId] = useState({})
  const [searchTerm, setSearchTerm] = useState(null)
  const [action, setAction] = useState("")
  const [uploadedImage, setUploadedImage] = useState()
  const [zoomLevel, setZoomLevel] = useState(1)
  const [modal, contextHolder] = Modal.useModal()
  const statusOptions = GetAllEmploymentStatus()
  const positionOptions = GetAllPositions()
  const businessUnitOptions = GetAllBusinessUnits()
  const departmentOptions = GetAllDepartments()
  const salaryTypeOptions = GetAllSalaryTypes()
  const employeeOptions = GetAllEmployees()
  const shiftOptions = GetAllShifts()
  const dateFormat = "YYYY-MM-DD"
  const [oldSalary, setOldSalary] = useState()
  const [oldPosition, setOldPosition] = useState()
  const requirementKeys = [
    { key: "health_certificate", label: "Health Certificate" },
    { key: "nbi_clearance", label: "NBI Clearance" },
    { key: "police_clearance", label: "Police Clearance" },
    { key: "barangay_clearance", label: "Brgy. Certificate" },
    { key: "tor", label: "Transcript of Records" },
    { key: "solo_parent", label: "Solo Parent ID/Certification" },
  ]
  const defaultFiles = {
    health_certificate: [],
    nbi_clearance: [],
    police_clearance: [],
    barangay_clearance: [],
    tor: [],
    solo_parent: [],
    health_certificate_size: false,
    nbi_clearance_size: false,
    police_clearance_size: false,
    barangay_clearance_size: false,
    tor_size: false,
    solo_parent_size: false,
  }
  const [requirements, setRequirements] = useState({ ...defaultFiles })
  const [showUploadFileModal, setShowUploadFileModal] = useState(false)

  const { callPost, isLoading, data } = usePostRequest(() => {})

  const [filterConfig, setFilterConfig] = useState({
    is_updated: false,
    employee_id: window.$isEmployeeLogin ? employeeId : "",
  })

  const tableColumns = [
    {
      title: "DTR ID",
      dataIndex: "dtr_id",
      key: "dtr_id",
    },
    {
      title: "Employee",
      dataIndex: "fullname",
      key: "fullname",
      render: (fullname) => <div>{capitalizeEachWord(fullname)}</div>,
    },
    {
      title: "Date Hired",
      dataIndex: "date_hired",
      key: "date_hired",
      render: (date_hired, record) => (
        <div>{date_hired ? moment(date_hired).format("MMM DD, YYYY") : ""}</div>
      ),
    },
    {
      title: "Date Regularized",
      dataIndex: "date_regularized",
      key: "date_regularized",
      render: (date_regularized, record) => (
        <div>
          {date_regularized
            ? moment(date_regularized).format("MMM DD, YYYY")
            : ""}
        </div>
      ),
    },
    {
      title: "End of Contract",
      dataIndex: "end_of_contract",
      key: "end_of_contract",
      render: (end_of_contract, record) => (
        <div>
          {end_of_contract
            ? moment(end_of_contract).format("MMM DD, YYYY")
            : ""}
        </div>
      ),
    },
    {
      title: "Position",
      dataIndex: "position_name",
      key: "position_name",
    },
    {
      title: "Actions",
      width: "10%",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="edit"
                onClick={() => {
                  handleRowClick(record)
                  setShowEditModal(true)
                }}
              >
                Edit
              </Menu.Item>

              {window.$isAdminLogin && (
                <Menu.Item
                  key="allowance"
                  onClick={() => {
                    handleRowClick(record)
                    setShowAllowanceModal(true)
                  }}
                >
                  Update Allowance
                </Menu.Item>
              )}

              {(role === "hr" || role === "admin") && (
                <>
                  <Menu.Item
                    key="contract"
                    onClick={() => {
                      handleRowClick(record)
                      setShowContractModal(true)
                      setFormAction("edit")
                    }}
                  >
                    Update Employment Info
                  </Menu.Item>
                </>
              )}

              <Menu.Item
                key="updateProfile"
                onClick={() => {
                  setClickedRowId(record)
                  setShowUploadProfile(true)
                }}
              >
                Update Profile Picture
              </Menu.Item>
              <Menu.Item
                key="upload"
                onClick={() => getEmployeeRequirements(record)}
              >
                Upload Requirements
              </Menu.Item>
              <Menu.Item
                key="history"
                onClick={() => navigate("history-report", { state: record })}
              >
                View History Report
              </Menu.Item>
              <Menu.Item
                key="profile"
                onClick={() =>
                  navigate("view", { state: { passedId: record.id } })
                }
              >
                View Profile
              </Menu.Item>
              <Menu.Item
                key="delete"
                className="danger"
                onClick={() => confirmDelete(record)}
              >
                Delete
              </Menu.Item>
              {/* </>
              )} */}
            </Menu>
          }
          trigger={["click"]}
        >
          <Button
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            Actions <DownOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ]

  function postCallPostAction() {
    fetchTableData()
    setRequirements(defaultFiles)
    resetValues()
    setShowAddModal(false)
    setShowEditModal(false)
    setShowContractModal(false)
    setShowAllowanceModal(false)
    setShowUploadFileModal(false)
    setShowUploadProfile(false)
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  // Before upload function for profile picture
  const beforeUploadProfile = (file) => {
    const isSizeValid = file.size / 1024 / 1024 < 1 // Check if file size is less than 1MB (backend request)
    if (!isSizeValid) {
      toast.error("File cannot exceed 1MB")
      return Upload.LIST_IGNORE
    } else {
      setUploadedImage(file)
      setPhotoList([...photoList, file])
    }
    return false
  }

  // Before upload function for uploading requirements
  const beforeUploadRequirements = (file, field) => {
    const isSizeValid = file.size / 1024 / 1024 < 2 // Check if file size is less than 1MB (backend request)
    if (!isSizeValid) {
      setRequirements((prev) => ({ ...prev, [`${field}_size`]: true }))
      return Upload.LIST_IGNORE
    } else {
      setRequirements((prev) => ({ ...prev, [`${field}_size`]: false }))
      setRequirements((prev) => ({ ...prev, [field]: [...prev[field], file] }))
    }
    return false
  }

  const handleZoomChange = (value) => {
    setZoomLevel(value)
  }

  const onFinish = (formInputs) => {
    // filter out empty object and child object where name and age are empty
    const filteredChildrenArray = formInputs.childrenArray.filter((child) => {
      return (
        Object.keys(child).length > 0 &&
        child.child_name &&
        child.child_name.trim() !== "" &&
        child.child_age &&
        child.child_age !== ""
      )
    })
    const childLen = filteredChildrenArray.length
    formInputs.no_of_children = childLen
    formInputs.children =
      childLen > 0 ? filteredChildrenArray.map((item) => item.child_name) : []
    formInputs.age =
      childLen > 0
        ? filteredChildrenArray.map((item) => item.child_age || "")
        : []
    formInputs.occupation =
      childLen > 0
        ? filteredChildrenArray.map((item) => item.child_occupation || "")
        : []
    formInputs.birthdate = formInputs.birthdate?.format(dateFormat) || ""
    formInputs.date_hired = formInputs.date_hired?.format(dateFormat) || ""
    formInputs.end_of_contract =
      formInputs.end_of_contract?.format(dateFormat) || ""
    formInputs.date_regularized =
      formInputs.date_regularized?.format(dateFormat) || ""
    formInputs.date_terminated_resigned =
      formInputs.date_terminated_resigned?.format(dateFormat) || ""
    formInputs.role_id = 1

    // Send empty string values to undefined fields (these are fields with no input at all)
    Object.keys(formInputs).forEach((key) => {
      if (formInputs[key] === undefined) {
        formInputs[key] = ""
      }
    })
    callPost("employees/add", formInputs, false)
  }

  const onEdit = (formInputs) => {
    if (formInputs.childrenArray && formInputs.childrenArray.length > 0) {
      formInputs.childrenArray.filter((child) => {
        return (
          Object.keys(child).length > 0 &&
          child.child_name !== "" &&
          child.child_age !== ""
        )
      })
    }
    const childLen = formInputs.childrenArray.length
    formInputs.no_of_children = childLen
    formInputs.children =
      childLen > 0
        ? formInputs.childrenArray.map((item) => item.child_name)
        : []
    formInputs.age =
      childLen > 0 ? formInputs.childrenArray.map((item) => item.child_age) : []
    formInputs.occupation =
      childLen > 0
        ? formInputs.childrenArray.map((item) => item.child_occupation || "")
        : []
    formInputs.birthdate = formInputs.birthdate
      ? formInputs.birthdate.format(dateFormat)
      : ""
    formInputs.date_hired = formInputs.date_hired
      ? formInputs.date_hired.format(dateFormat)
      : ""
    formInputs.end_of_contract = formInputs.end_of_contract
      ? formInputs.end_of_contract.format(dateFormat)
      : ""
    formInputs.date_regularized = formInputs.date_regularized
      ? formInputs.date_regularized.format(dateFormat)
      : ""
    formInputs.date_terminated_resigned = formInputs.date_terminated_resigned
      ? formInputs.date_terminated_resigned.format(dateFormat)
      : ""

    formInputs.salary = formInputs.salary_to

    // Send empty string values to undefined fields (these are fields with no input at all)
    Object.keys(formInputs).forEach((key) => {
      if (formInputs[key] === undefined || formInputs[key] === null) {
        formInputs[key] = ""
      }
    })

    callPost(`employees/update/` + clickedRowId, formInputs, false)

    // UPDATE SALARY
    if (
      parseFloat(formInputs.salary_to).toFixed(2) !==
      parseFloat(oldSalary).toFixed(2)
    ) {
      const salaryValues = {
        employee_id: clickedRowId,
        salary_from: oldSalary,
        salary_to: formInputs.salary_to,
        salary_type: formInputs.salary_type,
      }
      callPost("employees/update_employee_salary", salaryValues, false)
    }

    // UPDATE POSITION
    if (
      parseFloat(formInputs.position_to).toFixed(2) !==
      parseFloat(oldPosition).toFixed(2)
    ) {
      const positionValues = {
        employee_id: clickedRowId,
        position_from: oldSalary,
        position_to: formInputs.position_to,
      }
      callPost("employees/update_employee_position", positionValues, false)
    }
  }

  const confirmDelete = (employee) => {
    modal.confirm({
      title: `Are you sure you want to delete this employee?`,
      icon: <ExclamationCircleOutlined />,
      content: "You cannot undo this action.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: () => handleDelete(employee.id),
      okType: "danger",
    })
  }

  const createFileFromDataUrl = (dataUrl, filename) => {
    const arr = dataUrl.split(",")
    const mimeMatch = arr[0].match(/:(.*?);/)
    const mime = mimeMatch && mimeMatch[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }

  const handleUpdatePicture = async (formInputs) => {
    if (editorRef.current) {
      const editedImage = editorRef.current.getImageScaledToCanvas().toDataURL()
      const imageFile = createFileFromDataUrl(editedImage, uploadedImage.name)

      const compressedImage = await new Promise((resolve, reject) => {
        new Compressor(imageFile, {
          quality: 0.6,
          success(result) {
            resolve(result)
          },
          error(err) {
            reject(err)
          },
        })
      })

      const tempImages = {
        employee_id: clickedRowId.id,
        profile_pic: compressedImage,
      }
      const finalValues = Object.entries(tempImages).map(([key, value]) => ({
        key,
        value,
      }))
      callPost("employees/upload_profile", finalValues, true)
    }
  }

  function handleDelete(passedId) {
    callPost("employees/delete", { employee_id: passedId }, false)
  }

  function resetValues() {
    form.resetFields()
    statusForm.resetFields()
    filesForm.resetFields()
    setRequirements(defaultFiles)
    setShowUploadFileModal(false)
  }

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setFilterConfig((prev) => ({ ...prev, term: searchTerm }))
    }
  }

  const handleFilter = (e, field) => {
    setFilterConfig((prev) => ({ ...prev, [field]: e }))
  }

  const handleRowClick = (rowData) => {
    setClickedRowId(rowData.id)
    getEmployeeDetailsById(rowData.id)
  }

  async function getEmployeeDetailsById(passedId) {
    const response = await UsePostRequest("employees/get", {
      employee_id: passedId,
    })
    if (response.data) {
      const res = response.data[0]
      const formValues = { ...res }
      formValues.birthdate = res.birthdate
        ? dayjs(res.birthdate, dateFormat)
        : ""
      formValues.date_hired = res.date_hired
        ? dayjs(res.date_hired, dateFormat)
        : ""
      formValues.end_of_contract = res.end_of_contract
        ? dayjs(res.end_of_contract, dateFormat)
        : ""
      formValues.date_regularized = res.date_regularized
        ? dayjs(res.date_regularized, dateFormat)
        : ""
      formValues.date_terminated_resigned = res.date_terminated_resigned
        ? dayjs(res.date_terminated_resigned, dateFormat)
        : ""
      formValues.salary_type = res.salary[0]?.salary_type
      formValues.salary_to = parseFloat(res.salary[0]?.salary_to)
      setOldSalary(res.salary[0]?.salary_to)
      setOldPosition(res.position_to)
      formValues.business_unit_id = "1"
      formValues.daily_allowance = res.allowance[0]?.daily_allowance
      formValues.commu_allowance = res.allowance[0]?.commu_allowance
      formValues.transpo_allowance = res.allowance[0]?.transpo_allowance
      formValues.food_allowance = res.allowance[0]?.food_allowance
      formValues.uniform_allowance = res.allowance[0]?.uniform_allowance
      formValues.employment_status_id = res.employment_status_id

      if (res.position_to === "0" || !res.position_to) {
        formValues.position_to = positionOptions[1].value
      }

      var tempChildren = []

      if (res.children && res.children.length > 0) {
        tempChildren = res.children.map((item) => {
          var info = {}
          info.child_name = item.name
          info.child_age = item.age
          info.child_occupation = item.occupation
          return info
        })
      }

      formValues.childrenArray = tempChildren
      form.setFieldsValue(formValues)
      const statusFormValues = {}
      statusFormValues.employee_id = res.id
      statusFormValues.employee_status_id = res.employee_status_id
      statusForm.setFieldsValue(statusFormValues)
    }
  }

  async function fetchTableData() {
    setIsFetching(true)
    setTableData([])
    const response = await UsePostRequest("employees/get_all", filterConfig)
    if (response.data) {
      const res = response.data.map((data) => {
        var info = data
        const tempName = `${data.first_name} ${data.middle_name} ${data.last_name}`
        info.fullname = capitalizeEachWord(tempName)
        return info
      })
      setTableData(res)
    }
    setIsFetching(false)
  }

  async function getEmployeeRequirements(rowData) {
    setClickedRowId(rowData.id)
    setRequirements([])
    const response = await UsePostRequest(
      "employees/get_employee_requirements",
      { employee_id: rowData.id }
    )
    if (response.response) {
      const res = response.response.file_url
      // Function to populate fileList for the Ant Design Upload component
      const updatedFileList = {
        health_certificate: res.health_certificate
          ? res.health_certificate.map((file) => {
              return {
                uid: file.id,
                name: file.file_name,
                status: "done",
                url: file.file_url,
                fileType: file.file_type,
              }
            })
          : [],
        nbi_clearance: res.nbi_clearance
          ? res.nbi_clearance.map((file) => {
              return {
                uid: file.id,
                name: file.file_name,
                status: "done",
                url: file.file_url,
                fileType: file.file_type,
              }
            })
          : [],
        police_clearance: res.police_clearance
          ? res.police_clearance.map((file) => {
              return {
                uid: file.id,
                name: file.file_name,
                status: "done",
                url: file.file_url,
                fileType: file.file_type,
              }
            })
          : [],
        tor: res.tor
          ? res.tor.map((file) => {
              return {
                uid: file.id,
                name: file.file_name,
                status: "done",
                url: file.file_url,
                fileType: file.file_type,
              }
            })
          : [],
        barangay_clearance: res.barangay_clearance
          ? res.barangay_clearance.map((file) => {
              return {
                uid: file.id,
                name: file.file_name,
                status: "done",
                url: file.file_url,
                fileType: file.file_type,
              }
            })
          : [],
        solo_parent: res.solo_parent
          ? res.solo_parent.map((file) => {
              return {
                uid: file.id,
                name: file.file_name,
                status: "done",
                url: file.file_url,
                fileType: file.file_type,
              }
            })
          : [],
      }
      filesForm.setFieldsValue(updatedFileList)
      setRequirements(updatedFileList)
    } else {
      filesForm.setFieldsValue(defaultFiles)
      setRequirements(defaultFiles)
    }
    setTimeout(() => {
      setShowUploadFileModal(true)
    }, 1000)
  }

  // Function to call API for uploading requirements
  const handleUploadRequirements = (formInputs) => {
    setAction("upload-requirements")
    const values = {
      employee_id: clickedRowId,
      health_certificate: [],
      health_certificate_id: [],
      nbi_clearance: [],
      nbi_clearance_id: [],
      police_clearance: [],
      police_clearance_id: [],
      barangay_clearance: [],
      barangay_clearance_id: [],
      solo_parent: [],
      solo_parent_id: [],
      tor: [],
      tor_id: [],
    }

    const emptyFile = new File([""], "", { type: "application/octet-stream" })

    ;(formInputs.health_certificate ?? []).forEach((file) => {
      if (file.originFileObj) {
        values.health_certificate.push(file.originFileObj)
        values.health_certificate_id.push("")
      } else {
        values.health_certificate.push(emptyFile)
        values.health_certificate_id.push(file.uid)
      }
    })
    ;(formInputs.nbi_clearance ?? []).forEach((file) => {
      if (file.originFileObj) {
        values.nbi_clearance.push(file.originFileObj)
        values.nbi_clearance_id.push("")
      } else {
        values.nbi_clearance.push(emptyFile)
        values.nbi_clearance_id.push(file.uid)
      }
    })
    ;(formInputs.police_clearance ?? []).forEach((file) => {
      if (file.originFileObj) {
        values.police_clearance.push(file.originFileObj)
        values.police_clearance_id.push("")
      } else {
        values.police_clearance.push(emptyFile)
        values.police_clearance_id.push(file.uid)
      }
    })
    ;(formInputs.barangay_clearance ?? []).forEach((file) => {
      if (file.originFileObj) {
        values.barangay_clearance.push(file.originFileObj)
        values.barangay_clearance_id.push("")
      } else {
        values.barangay_clearance.push(emptyFile)
        values.barangay_clearance_id.push(file.uid)
      }
    })
    ;(formInputs.solo_parent ?? []).forEach((file) => {
      if (file.originFileObj) {
        values.solo_parent.push(file.originFileObj)
        values.solo_parent_id.push("")
      } else {
        values.solo_parent.push(emptyFile)
        values.solo_parent_id.push(file.uid)
      }
    })
    ;(formInputs.tor ?? []).forEach((file) => {
      if (file.originFileObj) {
        values.tor.push(file.originFileObj)
        values.tor_id.push("")
      } else {
        values.tor.push(emptyFile)
        values.tor_id.push(file.uid)
      }
    })

    var finalValues = Object.entries(values).map(([key, value]) => ({
      key,
      value,
    }))

    callPost("employees/upload_requirements", finalValues, true)
  }

  React.useEffect(() => {
    fetchTableData()
  }, [filterConfig])

  // These functions are the different input field sections in every modal in the employee module
  // Decided to put them as functions since I will be reusing them across the code
  // For which section every function is should be self explanatory.
  // Why do I need to reuse them in every modal? Because for some reason, if these sections are not included in the Form,
  // their values disappear sa form (which I need by the way even if I only edit one section at once)

  function formProfileSection() {
    return (
      <>
        <Divider />
        <Row>
          <Title level={5}>Personal Information</Title>
        </Row>
        <Row>
          <Col xs={12} md={8} lg={8}>
            <Form.Item
              name="last_name"
              label="Last Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item
              name="first_name"
              label="First Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item name="middle_name" label="Middle Name">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item name="suffix" label="Suffix">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item name="nickname" label="Nickname">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item
              name="contact_no"
              label="Contact Number"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input type="number" onWheel={(e) => e.target.blur()} />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <Form.Item
              name="address"
              label="Local Home Address"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} lg={16}>
            <Form.Item
              name="bir_registered_address"
              label="BIR Registered Address"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} lg={8}>
            <Form.Item
              name="zip_code"
              label="ZIP Code"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input type="number" onWheel={(e) => e.target.blur()} />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item
              name="email"
              label="Email Address"
              rules={[
                {
                  required: true,
                },
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item
              name="gender"
              label="Gender"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={[
                  { value: "male", label: "Male" },
                  { value: "female", label: "Female" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item
              name="birthdate"
              label="Birthdate"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker placeholder="YYYY-MM-DD" />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item
              name="civil_status"
              label="Civil Status"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={[
                  { value: "single", label: "Single" },
                  { value: "married", label: "Married" },
                  { value: "widowed", label: "Widowed" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item
              name="nationality"
              label="Nationality"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item name="religion" label="Religion">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Divider />
        <Row>
          <Title level={5}>Benefit Contribution Information</Title>
        </Row>
        <Row>
          <Col xs={12} md={8} lg={8}>
            <Form.Item name="sss" label="SSS Number">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item name="hdmf" label="HDMF Number">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item name="phic" label="PhilHealth Number">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item name="hmo_number" label="HMO Number">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item name="insurance_number" label="Insurance Number">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Divider />
        <Row>
          <Title level={5}>Withholding Tax Information</Title>
        </Row>
        <Row>
          <Col xs={12} md={8} lg={8}>
            <Form.Item
              name="tin"
              label="TIN Number"
              rules={[
                {
                  pattern: /^\d{3}-\d{3}-\d{3}-\d{3}$/,
                  message: "Input must be in the format 123-456-789-000",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Divider />
        <Row>
          <Title level={5}>Family Relationship Information</Title>
        </Row>
        <Row>
          <Col xs={10} md={10} lg={10}>
            <Form.Item name="spouse_name" label="Spouse Name">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={4} md={4} lg={4}>
            <Form.Item name="spouse_age" label="Age">
              <InputNumber type="number" onWheel={(e) => e.target.blur()} />
            </Form.Item>
          </Col>
          <Col xs={10} md={10} lg={10}>
            <Form.Item name="spouse_occupation" label="Occupation">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={10} md={10} lg={10}>
            <Form.Item name="mothers_name" label="Mother's Name">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={4} md={4} lg={4}>
            <Form.Item name="mothers_age" label="Age">
              <InputNumber type="number" onWheel={(e) => e.target.blur()} />
            </Form.Item>
          </Col>
          <Col xs={10} md={10} lg={10}>
            <Form.Item name="mothers_occupation" label="Occupation">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={10} md={10} lg={10}>
            <Form.Item name="fathers_name" label="Father's Name">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={4} md={4} lg={4}>
            <Form.Item name="fathers_age" label="Age">
              <InputNumber type="number" onWheel={(e) => e.target.blur()} />
            </Form.Item>
          </Col>
          <Col xs={10} md={10} lg={10}>
            <Form.Item name="fathers_occupation" label="Occupation">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.List name="childrenArray" initialValue={[{}]}>
            {(fields, { add, remove }) => (
              <>
                <Col xs={24} lg={24}>
                  {fields.map(({ key, name, fieldKey }) => (
                    <Row
                      key={key}
                      style={{ marginBottom: 0 }}
                      className="d-flex justify-content-between"
                    >
                      <Col xs={10}>
                        <Form.Item
                          name={[name, "child_name"]}
                          label="Child Name"
                          fieldKey={[fieldKey, "child_name"]}
                          rules={[{ required: false }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={4}>
                        <Form.Item
                          label="Age"
                          name={[name, "child_age"]}
                          fieldKey={[fieldKey, "child_age"]}
                        >
                          <InputNumber
                            type="number"
                            onWheel={(e) => e.target.blur()}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={8}>
                        <Form.Item
                          label="Occupation"
                          name={[name, "child_occupation"]}
                          fieldKey={[fieldKey, "child_occupation"]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={2} className="mt-4 p-1">
                        <Button
                          onClick={() => {
                            remove(name)
                          }}
                          icon={<DeleteOutlined />}
                        />
                      </Col>
                    </Row>
                  ))}
                </Col>
                <Row>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusCircleOutlined />}
                  >
                    Add Child
                  </Button>
                </Row>
              </>
            )}
          </Form.List>
        </Row>

        <Divider />
        <Row>
          <Title level={5}>Person to Contact in Case of Emergency</Title>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <Form.Item
              name="contact_person"
              label="Contact Person"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} md={12} lg={12}>
            <Form.Item
              name="contact_person_relationship"
              label="Relationship to Contact Person"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} md={12} lg={12}>
            <Form.Item
              name="contact_number"
              label="Contact Number"
              rules={[{ required: true }]}
            >
              <Input type="number" onWheel={(e) => e.target.blur()} />
            </Form.Item>
          </Col>
          <Col xs={12} md={12} lg={12}>
            <Form.Item
              name="contact_address"
              label="Contact Address"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </>
    )
  }

  function formEmploymentInfoSection() {
    return (
      <>
        <Divider />
        <Row>
          <Title level={5}>Employment Information</Title>
        </Row>
        <Row>
          <Col xs={12} md={8} lg={8}>
            <Form.Item
              name="dtr_id"
              label="DTR ID"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={12} md={8} lg={8}>
            <Form.Item
              name="business_unit_id"
              label="Business Unit"
              rules={[{ required: true }]}
            >
              <Select options={businessUnitOptions.slice(1)} disabled />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item
              name="employment_status_id"
              label="Employment Status"
              rules={[{ required: true }]}
            >
              <Select options={statusOptions.slice(1)} />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item
              name="salary_type"
              label="Salary Type"
              rules={[{ required: true }]}
            >
              <Select options={salaryTypeOptions} />
            </Form.Item>
          </Col>

          <Col
            xs={12}
            md={8}
            lg={8}
            className={`${
              role !== "admin" && formAction === "edit" ? "hidden" : "flex"
            }`}
          >
            <Form.Item
              name="salary_to"
              label="Basic Salary"
              rules={[{ required: true }]}
            >
              <InputNumber
                type="number"
                onWheel={(e) => e.target.blur()}
                min={1}
              />
            </Form.Item>
          </Col>

          <Col xs={12} md={8} lg={8}>
            <Form.Item name="bank" label="Bank">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item name="bank_account_name" label="Bank Account Name">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item name="bank_account_number" label="Bank Account Number">
              <InputNumber type="text" onWheel={(e) => e.target.blur()} />
            </Form.Item>
          </Col>

          <Col xs={12} md={8} lg={8}>
            <Form.Item name="immediate_head_id" label="Immediate Head">
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select employee"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) => {
                  if (optionA.label?.includes("All")) return -1
                  if (optionB.label?.includes("All")) return 1
                  return optionA.label
                    .toLowerCase()
                    .localeCompare(optionB.label.toLowerCase())
                }}
                options={employeeOptions.slice(1)}
              />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item
              name="department_id"
              label="Department"
              rules={[{ required: true }]}
            >
              <Select options={departmentOptions.slice(1)} />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item
              name="position_to"
              label="Position"
              rules={[{ required: true }]}
            >
              <Select options={positionOptions.slice(1)} />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item
              name="date_hired"
              label="Date Hired"
              rules={[{ required: true }]}
            >
              <DatePicker placeholder="YYYY-MM-DD" />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item name="end_of_contract" label="End of Contract">
              <DatePicker placeholder="YYYY-MM-DD" />
            </Form.Item>
          </Col>

          <Col xs={12} md={8} lg={8}>
            <Form.Item name="date_regularized" label="Date Regularized">
              <DatePicker placeholder="YYYY-MM-DD" />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item
              name="date_terminated_resigned"
              label="Date Terminated/Resigned"
            >
              <DatePicker placeholder="YYYY-MM-DD" />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item name="previous_employer" label="Previous Employer">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item
              name="current_shift_id"
              label="Current Shift"
              rules={[{ required: true }]}
            >
              <Select options={shiftOptions.slice(1)} />
            </Form.Item>
          </Col>
        </Row>
      </>
    )
  }

  function formAllowanceSection() {
    return (
      <>
        <Divider />
        <Row>
          <Title level={5}>Allowance</Title>
        </Row>
        <Row>
          <Col xs={12} md={8} lg={8}>
            <Form.Item
              name="daily_allowance"
              label="Daily Allowance"
              initialValue={0}
            >
              <InputNumber
                min={0}
                type="number"
                onWheel={(e) => e.target.blur()}
              />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item
              name="commu_allowance"
              label="Communication Allowance"
              initialValue={0}
            >
              <InputNumber
                min={0}
                type="number"
                onWheel={(e) => e.target.blur()}
              />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item
              name="transpo_allowance"
              label="Transportation Allowance"
              initialValue={0}
            >
              <InputNumber
                min={0}
                type="number"
                onWheel={(e) => e.target.blur()}
              />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item
              name="food_allowance"
              label="Food Allowance"
              initialValue={0}
            >
              <InputNumber
                min={0}
                type="number"
                onWheel={(e) => e.target.blur()}
              />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={8}>
            <Form.Item
              name="uniform_allowance"
              label="Uniform Allowance"
              initialValue={0}
            >
              <InputNumber
                min={0}
                type="number"
                onWheel={(e) => e.target.blur()}
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    )
  }

  function renderFileForm() {
    return (
      <Form
        form={filesForm}
        layout="vertical"
        onFinish={handleUploadRequirements}
      >
        <Row className="w-100 mt-3">
          {requirementKeys.map((req, index) => (
            <Col xs={24} md={12} className="mt-2" key={index}>
              <Form.Item
                name={req.key}
                label={req.label}
                valuePropName="fileList"
                getValueFromEvent={normFile}
                tooltip="File must not exceed 1MB"
                initialValue={requirements[req.key]}
              >
                <Upload
                  name={req.key}
                  listType="list"
                  multiple={true}
                  onRemove={(file) => {
                    const index = requirements[req.key].indexOf(file)
                    const newFileList = requirements[req.key].slice()
                    newFileList.splice(index, 1)
                    setRequirements((prev) => ({
                      ...prev,
                      [req.key]: newFileList,
                    }))
                  }}
                  beforeUpload={(file) =>
                    beforeUploadRequirements(file, req.key)
                  }
                  fileList={requirements[req.key]}
                  showUploadList={true}
                  accept=".png,.jpeg,.jpg,.pdf"
                >
                  <Button icon={<UploadOutlined />}>Upload File</Button>
                </Upload>
                {requirements[`${req.key}_size`] && (
                  <div className="ant-form-item-explain-error">
                    File must not exceed 1MB
                  </div>
                )}
              </Form.Item>
            </Col>
          ))}
        </Row>

        <Form.Item>
          <Row className="d-flex justify-content-end mt-3">
            <Button
              danger
              onClick={() => {
                setAction("")
                resetValues()
              }}
              disabled={isLoading}
              className="me-2"
            >
              Cancel
            </Button>
            <AddSubmitButton form={filesForm} isLoading={isLoading} />
          </Row>
        </Form.Item>
      </Form>
    )
  }

  React.useEffect(() => {
    renderFileForm()
  }, [requirements])

  React.useEffect(() => {
    if (data && action === "upload-requirements") {
      refreshPage()
    } else {
      setTimeout(() => {
        postCallPostAction()
      }, 1000)
    }
  }, [data])

  console.log(role !== "hr")

  return (
    <Navbar
      pageTitle="Employees"
      pageButtonTitle="Add Employee"
      onClickButton={() => {
        setShowAddModal(true)
        setFormAction("add")
      }}
      setSearchQuery={(e) => setSearchTerm(e)}
      onSearch={handleSearch}
      searchPlaceholder="employee"
      isManager
      searchable
      // noButton={!isAdminLogin}
      noButton={role !== "admin" && role !== "hr"}
    >
      <Space className="mt-4">
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Status"
          optionFilterProp="items"
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) => {
            if (optionA.label?.includes("All")) return -1
            if (optionB.label?.includes("All")) return 1
            return optionA.label
              .toLowerCase()
              .localeCompare(optionB.label.toLowerCase())
          }}
          options={statusOptions}
          onChange={(e) => handleFilter(e, "employment_status_id")}
        />
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Position"
          optionFilterProp="items"
          filterOption={(input, option) => option.label.includes(input)}
          filterSort={(optionA, optionB) => {
            if (optionA.label?.includes("All")) return -1
            if (optionB.label?.includes("All")) return 1
            return optionA.label
              .toLowerCase()
              .localeCompare(optionB.label.toLowerCase())
          }}
          options={positionOptions}
          onChange={(e) => handleFilter(e, "position_id")}
        />
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Department"
          optionFilterProp="items"
          filterOption={(input, option) => option.label.includes(input)}
          filterSort={(optionA, optionB) => {
            if (optionA.label?.includes("All")) return -1
            if (optionB.label?.includes("All")) return 1
            return optionA.label
              .toLowerCase()
              .localeCompare(optionB.label.toLowerCase())
          }}
          options={departmentOptions}
          onChange={(e) => handleFilter(e, "department_id")}
        />
      </Space>
      <Table
        className=""
        rowKey="id"
        columns={tableColumns}
        dataSource={tableData}
        loading={isFetching}
      />

      {/* ADD MODAL */}
      <Modal
        maskClosable={false}
        title="Add Employee"
        centered
        open={showAddModal}
        footer={null}
        onCancel={() => {
          setShowAddModal(false)
          setShowEditModal(false)
          resetValues()
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={onFinish}
          initialValues={{ business_unit_id: "1" }}
        >
          {formProfileSection()}
          {formEmploymentInfoSection()}
          {formAllowanceSection()}

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Row>
                <Button
                  className="me-2"
                  danger
                  onClick={() => {
                    setShowAddModal(false)
                    resetValues()
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <AddSubmitButton form={form} isLoading={isLoading} />
              </Row>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      {/* EDIT MODAL */}
      <Modal
        maskClosable={false}
        title="Edit Employee"
        centered
        open={showEditModal}
        footer={null}
        onCancel={() => {
          setShowEditModal(false)
          resetValues()
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={onEdit}
        >
          {formProfileSection()}

          {/* these are hidden but cannot be removed because the values for some reason disappears from the form */}
          <div style={{ display: "none" }}>
            {formEmploymentInfoSection()}
            {formAllowanceSection()}
          </div>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Row>
                <Button
                  className="me-2"
                  danger
                  onClick={() => {
                    setShowEditModal(false)
                    resetValues()
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <AddSubmitButton form={form} isLoading={isLoading} />
              </Row>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      {/* UPDATE Employment Info */}
      <Modal
        maskClosable={false}
        title="Update Employment Information"
        centered
        open={showContractModal}
        footer={null}
        onCancel={() => {
          setShowContractModal(false)
          resetValues()
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={onEdit}
        >
          {formEmploymentInfoSection()}

          <div style={{ display: "none" }}>
            {formProfileSection()}
            {formAllowanceSection()}
          </div>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Row>
                <Button
                  className="me-2"
                  danger
                  onClick={() => {
                    setShowContractModal(false)
                    resetValues()
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <AddSubmitButton form={form} isLoading={isLoading} />
              </Row>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      {/* UPDATE ALLOWANCE MODAL */}
      <Modal
        maskClosable={false}
        title="Update Allowance"
        centered
        open={showAllowanceModal}
        footer={null}
        onCancel={() => {
          setShowAllowanceModal(false)
          resetValues()
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={onEdit}
        >
          {formAllowanceSection()}

          <div style={{ display: "none" }}>
            {formProfileSection()}
            {formEmploymentInfoSection()}
          </div>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Row>
                <Button
                  className="me-2"
                  danger
                  onClick={() => {
                    setShowAllowanceModal(false)
                    resetValues()
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <AddSubmitButton form={form} isLoading={isLoading} />
              </Row>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      {/* UPDATE PROFILE PICTURE */}
      <Modal
        title="Update Profile Picture"
        centered
        open={showUploadProfile}
        footer={null}
        onCancel={() => {
          setShowUploadProfile(false)
          setUploadedImage(null)
        }}
      >
        <Form
          className="mt-3 mx-3"
          name="validateOnly"
          layout="vertical"
          onFinish={handleUpdatePicture}
          form={pictureForm}
        >
          <Form.Item
            name="images"
            label="Photo"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            tooltip="Image must be of size 1000 x 250 px."
            rules={[{ required: true }]}
          >
            <Upload
              name="profile_picture"
              listType="picture"
              multiple={false}
              maxCount={1}
              onRemove={(file) => {
                const index = photoList.indexOf(file)
                const newFileList = photoList.slice()
                newFileList.splice(index, 1)
                setPhotoList(newFileList)
                setUploadedImage()
              }}
              beforeUpload={beforeUploadProfile}
              fileList={photoList}
              showUploadList={false}
              accept=".png, .jpg, .jpeg"
            >
              <Button icon={<UploadOutlined />}>Upload image</Button>
            </Upload>
          </Form.Item>
          {uploadedImage && (
            <>
              <Row className="justify-content-center">
                <AvatarEditor
                  ref={editorRef}
                  image={uploadedImage}
                  width={300}
                  height={350}
                  border={5}
                  scale={zoomLevel}
                  className="avatar-editor-image"
                />
              </Row>
              <Row className="d-flex justify-content-center mt-2">
                <input
                  className="w-75"
                  type="range"
                  min="0.2"
                  max="3"
                  step="0.1"
                  value={zoomLevel}
                  onChange={(e) => handleZoomChange(parseFloat(e.target.value))}
                />
              </Row>
            </>
          )}

          <Form.Item>
            <Row className="d-flex justify-content-between mt-3">
              <Button
                danger
                onClick={() => {
                  setUploadedImage(null)
                  setShowUploadProfile(false)
                }}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <AddSubmitButton form={pictureForm} isLoading={isLoading} />
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      {/* UPDATE requirements */}
      <Modal
        title="Upload Files"
        centered
        open={showUploadFileModal}
        footer={null}
        onCancel={() => {
          resetValues()
          setAction("")
        }}
        width={800}
      >
        {renderFileForm()}
      </Modal>
      {contextHolder}
    </Navbar>
  )
}
