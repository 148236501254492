import React, { useState } from "react";
import {
  Form,
  Button,
  Row,
  Space,
  Select,
  Card,
  Input,
  Table,
  Checkbox
} from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import { usePostRequest } from "../../hooks/usePostRequest";
import { UsePostRequest } from "../../utils/api/ApiRequests";
import {
  GetAllEmployees,
  GetAllEmploymentStatus,
} from "../../utils/api/DropdownFiltersApi";
import moment from "moment";
import { extractAmount, getSum, isValidNumber, refreshPage } from "../../utils/Common";
import "../../cssFiles/CustomStyles/Payroll.css";

const AddSubmitButton = ({ form, isLoading }) => {
  const [submittable, setSubmittable] = useState(false);
  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
  }, [values, form]);

  return (
    <Button
      type="primary"
      htmlType="submit"
      disabled={!submittable}
      loading={isLoading}
    >
      Submit
    </Button>
  );
};

export default function GeneratePayroll() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const salaryType = location.state.salary_type||"";
  const dateFrom = location.state.date_from;
  const dateTo = location.state.date_to;
  const { callPost, isLoading } = usePostRequest(() => {
    navigate("/payrolls");
    refreshPage();
  });
  const [filterConfig, setFilterConfig] = useState({
    salary_type: salaryType || '',
    date_from: moment(dateFrom.$d).format("YYYY-MM-DD"),
    date_to: moment(dateTo.$d).format("YYYY-MM-DD"),
    employee_id: "",
  });
  const employeeOptions = GetAllEmployees();
  const employmentStatusOptions = GetAllEmploymentStatus();
  const [tableData, setTableData] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isThirteenthIncluded, setIsThirteenthIncluded] = useState(false);

  const handleCheckbox = (e, field) => {
    setIsThirteenthIncluded(e.target.checked)
  };


  const tableColumns = [
    {
      title: "Employee",
      dataIndex: "employee_name",
      // width: 800,
      key: "employee_name",
      render: (text, record, index) => (
        <Form.Item name={[index, "employee_name"]} initialValue={text}>
          <Input disabled/>
        </Form.Item>
      ),
    },
    {
      title: "Rate",
      dataIndex: "basic_rate",
      key: "basic_rate",
      render: (text, record, index) => (
        <Form.Item name={[index, "basic_rate"]} initialValue={text}>
          <Input disabled/>
        </Form.Item>
      ),
    },
    {
      title: "Work Days",
      dataIndex: "work_days",
      key: "work_days",
      render: (text, record, index) => (
        <Form.Item name={[index, "work_days"]} initialValue={text}>
          <Input disabled/>
        </Form.Item>
      ),
    },
    {
      title: "Basic Pay",
      dataIndex: "basic_pay",
      key: "basic_pay",
      render: (text, record, index) => (
        <Form.Item
          name={[index, "basic_pay"]}
          initialValue={extractAmount(text)}
        >
          <Input disabled/>
        </Form.Item>
      ),
    },
    {
      title: "Regular OT (hrs)",
      dataIndex: "regular_ot_hrs",
      key: "regular_ot_hrs",
      render: (text, record, index) => (
        <Form.Item name={[index, "regular_ot_hrs"]} initialValue={text}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled />
        </Form.Item>
      ),
    },
    {
      title: "Regular OT Pay",
      dataIndex: "regular_ot_pay",
      key: "regular_ot_pay",
      render: (text, record, index) => (
        <Form.Item name={[index, "regular_ot_pay"]} initialValue={text}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled/>
        </Form.Item>
      ),
    },
    {
      title: "Restday OT (hrs)",
      dataIndex: "restday_ot_hrs",
      key: "restday_ot_hrs",
      render: (text, record, index) => (
        <Form.Item name={[index, "restday_ot_hrs"]} initialValue={text}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled />
        </Form.Item>
      ),
    },
    {
      title: "Restday OT Pay",
      dataIndex: "restday_ot_pay",
      key: "restday_ot_pay",
      render: (text, record, index) => (
        <Form.Item name={[index, "restday_ot_pay"]} initialValue={text}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled/>
        </Form.Item>
      ),
    },
    {
      title: "Regular Holiday Pay",
      dataIndex: "regular_holiday_pay",
      key: "regular_holiday_pay",
      render: (text, record, index) => (
        <Form.Item
          name={[index, "regular_holiday_pay"]}
          initialValue={extractAmount(text)}
        >
          <Input disabled/>
        </Form.Item>
      ),
    },
    {
      title: "Special Holiday Pay",
      dataIndex: "special_holiday_pay",
      key: "special_holiday_pay",
      render: (text, record, index) => (
        <Form.Item name={[index, "special_holiday_pay"]} initialValue={0}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled/>
        </Form.Item>
      ),
    },
    {
      title: "Leave Days",
      dataIndex: "leave_days",
      key: "leave_days",
      render: (text, record, index) => (
        <Form.Item name={[index, "leave_days"]} initialValue={0}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled/>
        </Form.Item>
      ),
    },
    {
      title: "Leave Pay",
      dataIndex: "leave_pay",
      key: "leave_pay",
      render: (text, record, index) => (
        <Form.Item name={[index, "leave_pay"]} initialValue={0}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled/>
        </Form.Item>
      ),
    },
    {
      title: "Daily Allowance",
      dataIndex: "daily_allowance",
      key: "daily_allowance",
      render: (text, record, index) => (
        <Form.Item name={[index, "daily_allowance"]} initialValue={text}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled />
        </Form.Item>
      ),
    },
    {
      title: "Comm Allowance",
      dataIndex: "comm_allowance",
      key: "comm_allowance",
      render: (text, record, index) => (
        <Form.Item name={[index, "comm_allowance"]} initialValue={text}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled />
        </Form.Item>
      ),
    },
    {
      title: "Transpo Allowance",
      dataIndex: "transpo_allowance",
      key: "transpo_allowance",
      render: (text, record, index) => (
        <Form.Item name={[index, "transpo_allowance"]} initialValue={text}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled />
        </Form.Item>
      ),
    },
    {
      title: "Food Allowance",
      dataIndex: "food_allowance",
      key: "food_allowance",
      render: (text, record, index) => (
        <Form.Item name={[index, "food_allowance"]} initialValue={text}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled />
        </Form.Item>
      ),
    },
    {
      title: "Uniform Allowance",
      dataIndex: "uniform_allowance",
      key: "uniform_allowance",
      render: (text, record, index) => (
        <Form.Item name={[index, "uniform_allowance"]} initialValue={text}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled />
        </Form.Item>
      ),
    },
    {
      title: "Night Diff (hrs)",
      dataIndex: "night_diff_hrs",
      key: "night_diff_hrs",
      render: (text, record, index) => (
        <Form.Item name={[index, "night_diff_hrs"]} initialValue={text}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled />
        </Form.Item>
      ),
    },
    {
      title: "Night Diff",
      dataIndex: "night_diff",
      key: "night_diff",
      render: (text, record, index) => (
        <Form.Item name={[index, "night_diff"]} initialValue={text}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled/>
        </Form.Item>
      ),
    },
    {
      title: "Salary Adjustment",
      dataIndex: "salary_adjustment",
      key: "salary_adjustment",
      render: (text, record, index) => (
        <Form.Item name={[index, "salary_adjustment"]} initialValue={0.00}>
          <Input type="number" onWheel={(e)=>e.target.blur()} defaultValue={0.00}/>
        </Form.Item>
      ),
    },
    {
      title: "Gross Pay",
      dataIndex: "gross_pay",
      key: "gross_pay",
      render: (text, record, index) => (
        <Input value={tableData[index].gross_pay} type="number" onWheel={(e)=>e.target.blur()} disabled />
      ),
    },
    {
      title: "Late (hrs)",
      dataIndex: "late_hrs",
      key: "late_hrs",
      render: (text, record, index) => (
        <Form.Item name={[index, "late_hrs"]} initialValue={text}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled />
        </Form.Item>
      ),
    },
    {
      title: "Late",
      dataIndex: "late",
      key: "late",
      render: (text, record, index) => (
        <Form.Item name={[index, "late"]} initialValue={text}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled/>
        </Form.Item>
      ),
    },
    {
      title: "Undertime (hrs)",
      dataIndex: "undertime_hrs",
      key: "undertime_hrs",
      render: (text, record, index) => (
        <Form.Item name={[index, "undertime_hrs"]} initialValue={text}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled />
        </Form.Item>
      ),
    },
    {
      title: "Undertime",
      dataIndex: "undertime_amount",
      key: "undertime_amount",
      render: (text, record, index) => (
        <Form.Item name={[index, "undertime_amount"]} initialValue={text}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled/>
        </Form.Item>
      ),
    },
    {
      title: "SSS",
      dataIndex: "sss_amount",
      key: "sss_amount",
      render: (text, record, index) => (
        <Form.Item name={[index, "sss_amount"]} initialValue={text}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled/>
        </Form.Item>
      ),
    },
    {
      title: "HDMF",
      dataIndex: "hdmf_amount",
      key: "hdmf_amount",
      render: (text, record, index) => (
        <Form.Item name={[index, "hdmf_amount"]} initialValue={text}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled/>
        </Form.Item>
      ),
    },
    {
      title: "PhilHealth",
      dataIndex: "phic_amount",
      key: "phic_amount",
      render: (text, record, index) => (
        <Form.Item name={[index, "phic_amount"]} initialValue={text}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled/>
        </Form.Item>
      ),
    },
    {
      title: "Withholding Tax",
      dataIndex: "wth_tax",
      key: "wth_tax",
      render: (text, record, index) => (
        <Form.Item name={[index, "wth_tax"]} initialValue={text}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled/>
        </Form.Item>
      ),
    },
    {
      title: "HMO",
      dataIndex: "hmo_amount",
      key: "hmo_amount",
      render: (text, record, index) => (
        <Form.Item name={[index, "hmo_amount"]} initialValue={0}>
          <Input type="number" onWheel={(e)=>e.target.blur()}/>
        </Form.Item>
      ),
    },
    {
      title: "Insurance",
      dataIndex: "insurance_amount",
      key: "insurance_amount",
      render: (text, record, index) => (
        <Form.Item name={[index, "insurance_amount"]} initialValue={0}>
          <Input type="number" onWheel={(e)=>e.target.blur()} defaultValue={0.00}/>
        </Form.Item>
      ),
    },
    {
      title: "SSS Salary Loan",
      dataIndex: "sss_salary_loan",
      key: "sss_salary_loan",
      render: (text, record, index) => (
        <Form.Item name={[index, "sss_salary_loan"]} initialValue={text}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled/>
        </Form.Item>
      ),
    },
    {
      title: "SSS Calamity Loan",
      dataIndex: "sss_calamity_loan",
      key: "sss_calamity_loan",
      render: (text, record, index) => (
        <Form.Item name={[index, "sss_calamity_loan"]} initialValue={text}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled/>
        </Form.Item>
      ),
    },
    {
      title: "HDMF Loan",
      dataIndex: "pagibig_loan",
      key: "pagibig_loan",
      render: (text, record, index) => (
        <Form.Item name={[index, "pagibig_loan"]} initialValue={text}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled/>
        </Form.Item>
      ),
    },
    {
      title: "Company Loan",
      dataIndex: "company_loan",
      key: "company_loan",
      render: (text, record, index) => (
        <Form.Item name={[index, "company_loan"]} initialValue={text}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled/>
        </Form.Item>
      ),
    },
    {
      title: "Multipurpose Loan",
      dataIndex: "multipurpose_loan",
      key: "multipurpose_loan",
      render: (text, record, index) => (
        <Form.Item name={[index, "multipurpose_loan"]} initialValue={text}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled/>
        </Form.Item>
      ),
    },
    {
      title: "Total Deductions",
      dataIndex: "total_deductions",
      key: "total_deductions",
      render: (text, record, index) => (
        <Input
          value={tableData[index].total_deductions}
          type="number" onWheel={(e)=>e.target.blur()}
          disabled
        />
      ),
    },
    {
      title: "Total Net Pay",
      dataIndex: "total_net_pay",
      key: "total_net_pay",
      render: (text, record, index) => (
        <Input value={tableData[index].total_net_pay} type="number" onWheel={(e)=>e.target.blur()} disabled />
      ),
    },
    {
      title: (
        <div className="d-flex justify-content-between">
          Thirteenth Month <Checkbox onChange={handleCheckbox}></Checkbox>
        </div>
      ),
      dataIndex: "thirteenth_month",
      key: "thirteenth_month",
      render: (text, record, index) => (
        <Form.Item name={[index, "thirteenth_month"]} initialValue={text}>
          <Input type="number" onWheel={(e)=>e.target.blur()} disabled/>
        </Form.Item>
      ),
    },
  ];

  const onFinish = (formInputs) => {
    const arrayOfValues = Object.values(formInputs);

    const finalValues = {
      date_from: moment(dateFrom.$d).format("YYYY-MM-DD"),
      date_to: moment(dateTo.$d).format("YYYY-MM-DD"),
      is_thirteenth_included: isThirteenthIncluded ? 1 : 0,
      salary_type: salaryType,
      employees: [],
      basic_rate: [],
      work_days: [],
      basic_pay: [],
      regular_ot_hr: [],
      regular_ot_pay: [],
      restday_ot_hr: [],
      restday_ot_pay: [],
      regular_holiday_pay: [],
      special_holiday_pay: [],
      leave_days: [],
      leave_pay: [],
      daily_allowance: [],
      comm_allowance: [],
      transpo_allowance: [],
      food_allowance: [],
      uniform_allowance: [],
      night_diff_hrs: [],
      night_diff: [],
      gross_pay: [],
      late_hr: [],
      late: [],
      undertime_hr: [],
      undertime_amount: [],
      sss: [],
      hdmf: [],
      philhealth: [],
      wth_tax: [],
      hmo_amount: [],
      insurance_amount: [],
      sss_salary_loan: [],
      sss_calamity_loan: [],
      pagibig_loan: [],
      company_loan: [],
      multipurpose_loan: [],
      salary_adj: [],
      total_deductions: [],
      total_net_pay: [],
      thirteenth_month: [],
      coop_loan: [],
    };

    arrayOfValues.forEach((data, index) => {
      finalValues["employees"][index] = tableData[index].employee_id;
      finalValues["basic_rate"][index] = data.basic_rate;
      finalValues["work_days"][index] = data.work_days;
      finalValues["basic_pay"][index] = data.basic_pay;
      finalValues["regular_ot_hr"][index] = data.regular_ot_hrs;
      finalValues["regular_ot_pay"][index] = data.regular_ot_pay;
      finalValues["restday_ot_hr"][index] = data.restday_ot_hrs;
      finalValues["restday_ot_pay"][index] = data.restday_ot_pay;
      finalValues["regular_holiday_pay"][index] = data.regular_holiday_pay;
      finalValues["special_holiday_pay"][index] = data.special_holiday_pay;
      finalValues["leave_days"][index] = data.leave_days;
      finalValues["leave_pay"][index] = data.leave_pay;
      finalValues["daily_allowance"][index] = data.daily_allowance;
      finalValues["comm_allowance"][index] = data.comm_allowance;
      finalValues["transpo_allowance"][index] = data.transpo_allowance;
      finalValues["food_allowance"][index] = data.food_allowance;
      finalValues["uniform_allowance"][index] = data.uniform_allowance;
      finalValues["night_diff_hrs"][index] = data.night_diff_hrs;
      finalValues["night_diff"][index] = data.night_diff;
      finalValues["gross_pay"][index] = tableData[index].gross_pay;
      finalValues["late_hr"][index] = data.late_hrs;
      finalValues["late"][index] = data.late;
      finalValues["undertime_hr"][index] = data.undertime_hrs;
      finalValues["undertime_amount"][index] = data.undertime_amount;
      finalValues["sss"][index] = data.sss_amount;
      finalValues["hdmf"][index] = data.hdmf_amount;
      finalValues["philhealth"][index] = data.phic_amount;
      finalValues["wth_tax"][index] = data.wth_tax;
      finalValues["hmo_amount"][index] = data.hmo_amount;
      finalValues["insurance_amount"][index] = data.insurance_amount;
      finalValues["sss_salary_loan"][index] = data.sss_salary_loan;
      finalValues["sss_calamity_loan"][index] = data.sss_calamity_loan;
      finalValues["pagibig_loan"][index] = data.pagibig_loan;
      finalValues["company_loan"][index] = data.company_loan;
      finalValues["multipurpose_loan"][index] = data.multipurpose_loan;
      finalValues["salary_adj"][index] = data.salary_adjustment;
      finalValues["total_deductions"][index] = tableData[index].total_deductions;
      finalValues['total_net_pay'][index] = isThirteenthIncluded?getSum([tableData[index].total_net_pay, tableData[index].thirteenth_month]):tableData[index].total_net_pay;
      finalValues["thirteenth_month"][index] = tableData[index].thirteenth_month;
    });
    callPost("payrolls/add", finalValues, false);
  };

  const handleFilter = (e, field) => {
    setFilterConfig((prev) => ({ ...prev, [field]: e }));
  };

  // Function to update grss pay, total deductions, and net pay
  const handleValueChange = (changedValues, allValues) => {
    const changedIndex = parseInt(Object.keys(changedValues)[0]);
    const info = allValues[changedIndex];
    const tempTableData = tableData[changedIndex];
  
    const tempGrossPay = getSum([
      tempTableData.gross_pay,
      info.salary_adjustment,
    ]);

    const tempTotalDeduction = getSum([
      tempTableData.total_deductions,
      info.hmo_amount,
      info.insurance_amount,
    ]);

    const totalNetPay = parseFloat(tempGrossPay-tempTotalDeduction).toFixed(2);

    setTableData((prevTableData) => {
        const updatedTableData = [...prevTableData];
        updatedTableData[changedIndex] = {
          ...updatedTableData[changedIndex],
          gross_pay: tempGrossPay,
          total_deductions: tempTotalDeduction,
          total_net_pay: totalNetPay,
        };
        return updatedTableData;
      });
  };

  React.useEffect(() => {
    async function fetchAutoFields() {
      setIsDataLoading(true);
      setTableData([]);
      const response = await UsePostRequest(
        "payrolls/get_auto_fields",
        filterConfig
      );
      if (response.data) {
        const res = response.data.map((row) => {
          var info = {};
          
          Object.keys(row).forEach((key) => {
            if ( // IF THE NUMBER IS PRESENTED AS A STRING, EXTRACT ITS AMOUNT
              row[key] &&
              isValidNumber(row[key]) &&
              !key.includes("date") &&
              !key.includes("id")
            )  {
              info[key] = extractAmount(row[key]);
            } else if (row[key] && typeof row[key] === "number") { // ELES IF THE NUMBER IS A NUMBER, REDUCE TO 2 DECIMAL PLACES
                info[key] = parseFloat(row[key]).toFixed(2)
            } else {
              info[key] = row[key];
            }
          });
          const tempGrossPay = getSum([
            info.basic_pay,
            info.regular_ot_pay,
            info.restday_ot_pay,
            info.regular_holiday_pay,
            info.special_holiday_pay,
            info.leave_pay,
            info.daily_allowance,
            info.comm_allowance,
            info.transpo_allowance,
            info.food_allowance,
            info.uniform_allowance,
            info.night_diff,
            info.paid_leave,
          ]);
      
          const tempTotalDeduction = getSum([
              info.late,
              info.undertime_amount,
              info.sss_amount,
              info.hdmf_amount,
              info.phic_amount,
              info.wth_tax,
              info.hmo_amount,
              info.insurance_amount, 
              info.sss_calamity_loan,
              info.pagibig_loan,
              info.calamity_loan,
              info.company_loan,
              info.multipurpose_loan,
              info.phone_loan,
              info.laptop_loan,
              info.salary_adj,
          ]);
      
          const totalNetPay = parseFloat(tempGrossPay-tempTotalDeduction).toFixed(2);
          info.gross_pay = tempGrossPay;
          info.total_deductions = tempTotalDeduction;
          info.total_net_pay = totalNetPay;

          return info;
        });
        form.setFieldsValue(res);
        setTableData(res);
      }
      setIsDataLoading(false);
    }

    fetchAutoFields();
  }, [filterConfig]);


  return (
    <Navbar formTitle="Generate Payroll" isForm>
      <Space className="mt-4">
        <Select
          style={{ width: 200 }}
          showSearch
          placeholder="Employee"
          optionFilterProp="items"
          filterOption={(input, option) => option.label?.toLowerCase().includes(input?.toLowerCase())}
          options={employeeOptions}
          onChange={(e) => handleFilter(e, "employee_id")}
        />
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Employment Status"
          optionFilterProp="items"
          filterOption={(input, option) =>
            option.label.toLowerCase().includes(input.toLowerCase())
          }
          filterSort={(optionA, optionB) => {
            if (optionA.label?.includes("All")) return -1;
            if (optionB.label?.includes("All")) return 1;
            return optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase());
          }}
          options={employmentStatusOptions}
          onChange={(e) => handleFilter(e, "employment_status_id")}
        />
      </Space>
      <Card className="p-0 mt-1">
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={onFinish}
          onValuesChange={handleValueChange}
        >
          <div className="payroll-table-wrapper">
            <Table
              dataSource={tableData}
              columns={tableColumns}
              rowKey="key"
              bordered
              pagination={false}
              loading={isDataLoading}
            />
          </div>
          <Form.Item>
            <Row className="d-flex justify-content-end mt-2">
              <Row>
                <Button
                  className="me-2"
                  danger
                  onClick={() => {
                    navigate("/payrolls");
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <AddSubmitButton form={form} isLoading={isLoading} />
              </Row>
            </Row>
          </Form.Item>
        </Form>
      </Card>

      {/* <Modal
        maskClosable={false}
        open={saving}
        footer={null}
        closeIcon={false}
      >
        <Row className="d-flex justify-content-center">
          <Title level={5}>Grenerating Payroll</Title>
        </Row>
        <Row className="d-flex justify-content-center mt-3">
          <Spin size="large"/>
        </Row>
        <Row className="d-flex justify-content-center mt-3">
          Please do not close this window.
        </Row>
        
      </Modal> */}
    </Navbar>
  );
}
