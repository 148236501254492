import React, { useState } from "react"
import { useLocation } from "react-router-dom"
import { Row, Table, Collapse, DatePicker } from "antd"
import Title from "antd/es/typography/Title"
import { Container } from "react-bootstrap"
import Navbar from "../../components/Navbar/Navbar"
import { UsePostRequest } from "../../utils/api/ApiRequests"
import {
  capitalizeEachWord,
  formatDate,
  formatShortDate,
} from "../../utils/Common"
import moment from "moment"
import { Line } from "react-chartjs-2"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  //   Title,
  Tooltip,
  Legend,
} from "chart.js"

const { Panel } = Collapse
const { RangePicker } = DatePicker

export default function EmployeeHistoryReport() {
  const location = useLocation()
  const employeeData = location.state
  const [leaveHistoryData, setLeaveHistoryData] = useState([])
  const [wfaHistoryData, setWfaHistoryData] = useState([])
  const [absentHistoryData, setAbsentHistoryData] = useState([])
  const [presentHistoryData, setPresentHistoryData] = useState([])
  const [regOtHistoryData, setRegOtHistoryData] = useState([])
  const [restDayOtHistoryData, setRestDayOtHistoryData] = useState([])
  const [lateHistoryData, setLateHistoryData] = useState([])
  const [undertimeHistoryData, setUndertimeHistoryData] = useState([])
  const dateToday = moment().format("YYYY-MM-DD")

  const [filterConfig, setFilterConfig] = useState({
    date_from: employeeData.date_from || dateToday,
    date_to: employeeData.date_to || dateToday,
    employee_id: employeeData.id,
  })

  const handleDateFilter = (e) => {
    if (e) {
      setFilterConfig((prev) => ({
        ...prev,
        date_from: e[0].format("YYYY-MM-DD"),
        date_to: e[1].format("YYYY-MM-DD"),
      }))
    }
  }

  const tableColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => <div>{formatShortDate(date)}</div>,
    },
  ]

  const tableColWithHr = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => <div>{formatShortDate(date)}</div>,
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
  ]

  function getMergeData(object) {
    console.log(object)
    var temp_array = []
    temp_array = object.map((data) => {
      var info = {}
      info.x = data.date
      info.y = data.minutes
      return info
    })
    return temp_array
  }

  console.log(getMergeData(regOtHistoryData))

  const regOtDataset = getMergeData(regOtHistoryData)
  const restDayOtDataset = getMergeData(restDayOtHistoryData)
  const lateDataset = getMergeData(lateHistoryData)
  const undertimeDataset = getMergeData(undertimeHistoryData)

  // Get the unique set of dates from both datasets
  const allDatesSet = new Set([
    ...regOtDataset.map((data) => data.x),
    ...restDayOtDataset.map((data) => data.x),
    ...lateDataset.map((data) => data.x),
    ...undertimeDataset.map((data) => data.x),
  ])
  const allDates = Array.from(allDatesSet).sort((a, b) => a - b)

  console.log(allDates)

  // Create aligned datasets with null values for missing dates
  const regOtLineData = allDates.map((date) => {
    const data = regOtDataset.find((item) => item.x === date)
    return data
      ? { x: formatShortDate(date), y: data.y }
      : { x: formatShortDate(date), y: 0 }
  })

  const restDayOtLineData = allDates.map((date) => {
    const data = restDayOtDataset.find((item) => item.x === date)
    return data
      ? { x: formatShortDate(date), y: data.y }
      : { x: formatShortDate(date), y: 0 }
  })

  const lateLineData = allDates.map((date) => {
    const data = lateDataset.find((item) => item.x === date)
    return data
      ? { x: formatShortDate(date), y: data.y }
      : { x: formatShortDate(date), y: 0 }
  })

  const undertimeLineData = allDates.map((date) => {
    const data = undertimeDataset.find((item) => item.x === date)
    return data
      ? { x: formatShortDate(date), y: data.y }
      : { x: formatShortDate(date), y: 0 }
  })

  const chartOptions = {
    responsive: true,
    scales: {
      x: {
        type: "category",
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "No. of Minutes",
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            // Access tooltipItem.raw.y to get the minutes
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw.y} minutes`
          },
        },
      },
    },
  }

  const lineData = {
    labels: allDates.map((date) => formatShortDate(date)), // X-axis labels (dates)
    datasets: [
      {
        label: "Regular OT",
        data: regOtLineData,
        borderColor: "red",
        backgroundColor: "rgba(255, 99, 132, 0.2)", // Optional for better visual contrast
        fill: false,
        lineTension: 0.1,
      },
      {
        label: "Rest Day OT",
        data: restDayOtLineData,
        borderColor: "orange",
        backgroundColor: "rgba(255, 159, 64, 0.2)",
        fill: false,
        lineTension: 0.1,
      },
      {
        label: "Late",
        data: lateLineData,
        borderColor: "green",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: false,
        lineTension: 0.1,
      },
      {
        label: "Undertime",
        data: undertimeLineData,
        borderColor: "blue",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        fill: false,
        lineTension: 0.1,
      },
    ],
  }

  const formatDuration = (hoursDecimal) => {
    const hours = Math.floor(hoursDecimal)
    const minutes = Math.round((hoursDecimal - hours) * 100) // Use *100 since .50 means 50 minutes, not half an hour
    const totalMinutes = hours * 60 + minutes // Calculate total minutes

    const duration = `${hours > 0 ? `${hours} hr${hours > 1 ? "s" : ""}` : ""}${
      hours > 0 && minutes > 0 ? " and " : ""
    }${minutes > 0 ? `${minutes} min${minutes > 1 ? "s" : ""}` : ""}`

    return { no_of_minutes: totalMinutes, duration }
  }

  React.useEffect(() => {
    async function fetchHistoryData() {
      setLeaveHistoryData([])
      setWfaHistoryData([])
      setAbsentHistoryData([])
      setPresentHistoryData([])
      setRegOtHistoryData([])
      setRestDayOtHistoryData([])
      setLateHistoryData([])
      setUndertimeHistoryData([])

      const response = await UsePostRequest(
        "timesheets/performance_evaluation_details",
        filterConfig
      )
      if (response.data) {
        const res = response.data[0]

        if (res.leave_dates) {
          const tempArray = res.leave_dates.map((item) => {
            var info = {}
            info.date = formatDate(item)
            return info
          })
          setLeaveHistoryData(tempArray)
        }

        if (res.wfa_dates) {
          const tempArray = res.wfa_dates.map((item) => {
            var info = {}
            info.date = formatDate(item)
            return info
          })
          setWfaHistoryData(tempArray)
        }

        if (res.absent_dates) {
          const tempArray = res.absent_dates.map((item) => {
            var info = {}
            info.date = formatDate(item)
            return info
          })
          setAbsentHistoryData(tempArray)
        }

        if (res.present_dates) {
          const tempArray = res.present_dates.map((item) => {
            var info = {}
            info.date = formatDate(item)
            return info
          })
          setPresentHistoryData(tempArray)
        }

        if (res.regular_ot_dates) {
          const tempArray = res.regular_ot_dates.map((item) => {
            // let temp = []
            // temp.push(
            //   formatShortDate(item.regular_ot_date),
            //   item.regular_ot_mins
            // )

            let temp = item
            const formattedTime = formatDuration(item.regular_ot_hr)
            temp.date = item.regular_ot_date
            temp.duration = formattedTime.duration
            temp.minutes = formattedTime.no_of_minutes
            return temp
          })
          setRegOtHistoryData(tempArray)
        }

        if (res.restday_ot_dates) {
          const tempArray = res.restday_ot_dates.map((item) => {
            // let temp = []
            // temp.push(
            //   formatShortDate(item.restday_ot_date),
            //   item.restday_ot_mins
            // )
            let temp = item
            temp.date = item.restday_ot_date
            const formattedTime = formatDuration(item.restday_ot_hr)
            temp.duration = formattedTime.duration
            temp.minutes = formattedTime.no_of_minutes
            return temp
          })
          setRestDayOtHistoryData(tempArray)
        }

        if (res.late_dates) {
          const tempArray = res.late_dates.map((item) => {
            // let temp = []
            // temp.push({
            //   date: formatShortDate(item.late_date),
            //   duration: item.late_mins,
            // })
            let temp = item
            temp.date = item.late_date
            const formattedTime = formatDuration(item.late_hr)
            temp.duration = formattedTime.duration
            temp.minutes = formattedTime.no_of_minutes
            return temp
          })
          setLateHistoryData(tempArray)
        }

        if (res.undertime_dates) {
          const tempArray = res.undertime_dates.map((item) => {
            // let temp = []
            // temp.push(formatShortDate(item.undertime_date), item.undertime_mins)
            let temp = item
            temp.date = item.undertime_date
            const formattedTime = formatDuration(item.undertime_hr)
            temp.duration = formattedTime.duration
            temp.minutes = formattedTime.no_of_minutes
            return temp
          })
          setUndertimeHistoryData(tempArray)
        }
      }
    }

    fetchHistoryData()
  }, [filterConfig])

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    // Title,
    Tooltip,
    Legend
  )

  return (
    <Navbar formPageTitle="Employee History Report" isForm>
      <Container>
        <Row>
          <Title level={4}>
            Employee: {capitalizeEachWord(employeeData.fullname)}
          </Title>
        </Row>
        <Row className="d-flex justify-content-end mt-3 mb-5">
          <RangePicker
            style={{ width: 500 }}
            onChange={handleDateFilter}
            placeholder={[filterConfig.date_from, filterConfig.date_to]}
          />
        </Row>
        <Line data={lineData} options={chartOptions} className="line-chart" />
        <Collapse accordion className="my-5 custom-collapse">
          <Panel header="Leave" key="leave">
            <Table dataSource={leaveHistoryData} columns={tableColumns} />
          </Panel>
          <Panel header="Work From Anywhere" key="wfa">
            <Table dataSource={wfaHistoryData} columns={tableColumns} />
          </Panel>
          <Panel header="Absent" key="absent">
            <Table dataSource={absentHistoryData} columns={tableColumns} />
          </Panel>
          <Panel header="Present" key="present">
            <Table dataSource={presentHistoryData} columns={tableColumns} />
          </Panel>
          <Panel header="Regular OT" key="regular_ot">
            <Table dataSource={regOtHistoryData} columns={tableColWithHr} />
          </Panel>
          <Panel header="Restday OT" key="restday_ot">
            <Table dataSource={restDayOtHistoryData} columns={tableColWithHr} />
          </Panel>
          <Panel header="Late" key="late">
            <Table dataSource={lateHistoryData} columns={tableColWithHr} />
          </Panel>
          <Panel header="Undertime" key="undertime">
            <Table dataSource={undertimeHistoryData} columns={tableColWithHr} />
          </Panel>
        </Collapse>
      </Container>
    </Navbar>
  )
}
