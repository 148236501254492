import React, { useState } from "react"
import Navbar from "../../components/Navbar/Navbar"
import {
  Table,
  Select,
  Button,
  Modal,
  Row,
  Col,
  Form,
  DatePicker,
  TimePicker,
  Space,
  Input,
  Checkbox,
  Tabs,
} from "antd"
import Title from "antd/es/typography/Title"
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons"
import dayjs from "dayjs"
import moment from "moment"
import { UseGetRequest } from "../../utils/api/ApiRequests"
import { GetAllEmployees } from "../../utils/api/DropdownFiltersApi"
import { usePostRequest } from "../../hooks/usePostRequest"
import {
  getIsAdminLogin,
  capitalizeEachWord,
  getEmployeeId,
  getRoleName,
} from "../../utils/Common"
import toast from "react-hot-toast"

const AddSubmitButton = ({ form, isLoading }) => {
  const [submittable, setSubmittable] = useState(false)
  const values = Form.useWatch([], form)
  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true)
        },
        () => {
          setSubmittable(false)
        }
      )
  }, [values])

  return (
    <Button
      type="primary"
      htmlType="submit"
      disabled={!submittable}
      loading={isLoading}
    >
      Submit
    </Button>
  )
}

export default function Overtime() {
  const [form] = Form.useForm()
  const isAdminLogin = getIsAdminLogin()
  const [tableData, setTableData] = useState([])
  const role = getRoleName()
  const [isFetching, setIsFetching] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showViewModal, setShowViewModal] = useState(false)
  const [clickedRowData, setClickedRowData] = useState({})
  const [fileSizeExceeded, setFileSizeExceeded] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [modal, contextHolder] = Modal.useModal()
  const employeeOptions = GetAllEmployees()
  const dateFormat = "YYYY-MM-DD"
  const [searchTerm, setSearchTerm] = useState("")
  const [reason, setReason] = useState("")
  const [employeeDetails, setEmployeeDetails] = useState({})
  const [schedules, setSchedules] = useState([])

  const { callPost, isLoading } = usePostRequest(() => {
    setShowAddModal(false)
    setShowEditModal(false)
    setShowViewModal(false)
    setShowConfirmDelete(false)
    resetValues()
    setFilterConfig((prev) => ({ ...prev, is_updated: !prev.is_updated }))
  })

  const [filterConfig, setFilterConfig] = useState({
    is_updated: false,
    status: "pending",
    employee_id: window.$isEmployeeLogin ? getEmployeeId() : "",
  })

  const tableColumns = [
    {
      title: "Employee",
      dataIndex: "employee_name",
      key: "employee_name",
    },
    {
      title: "Shift",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date, record) => (
        <div>{moment(date).format("MMM DD, YYYY")}</div>
      ),
    },
    {
      title: "Overtime",
      dataIndex: "overtime",
      key: "overtime",
      render: (overtime, record) => (
        <div>{moment(overtime, "hh:mm A").format("hh:mm A")}</div>
      ),
    },
    {
      title: "No. of Overtime Hours",
      dataIndex: "ot_hours",
      key: "ot_hours",
      // render: (data, record) => (
      //   <div className="pointer-cursor" onClick={() => handleRowClick(record)}>
      //     {parseFloat(Number(data)*60).toFixed(2)}
      //   </div>
      // ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (data) => <div>{capitalizeEachWord(data)}</div>,
    },
  ]

  const onFinish = async (formInputs) => {
    formInputs.date = formInputs.date.format(dateFormat)
    formInputs.overtime = formInputs.overtime.format("HH:mm:ss")
    formInputs.status = "pending"
    formInputs.is_timein = formInputs.is_timein ? "1" : "0"
    formInputs.shift_id = employeeDetails.current_shift_id
    callPost("overtimes/add", formInputs, false)
  }

  const onEdit = async (formInputs) => {
    formInputs.date = formInputs.date.format(dateFormat)
    // formInputs.date_to = formInputs.date_to.format(dateFormat);
    formInputs.overtime = formInputs.overtime.format("HH:mm:ss")
    formInputs.is_timein = formInputs.is_timein ? "1" : "0"

    callPost(`overtimes/update/` + clickedRowData.id, formInputs, false)
  }

  const confirmApprove = (overtime, status) => {
    modal.confirm({
      title: `Are you sure you want to ${status} ${overtime.full_name}'s overtime?`,
      icon: <CheckCircleOutlined />,
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () => handleApprove(overtime.id, status),
    })
  }

  function handleApprove(passedId, status) {
    const newStatus = `${status}d`
    callPost(
      "overtimes/approve",
      { overtime_id: passedId, status: newStatus },
      false
    )
    setShowViewModal(false)
    resetValues()
    setFilterConfig((prev) => ({ ...prev, is_updated: !prev.is_updated }))
  }

  function handleDelete(passedId) {
    callPost(
      "overtimes/delete",
      { overtime_id: passedId, remarks: reason },
      false
    )
    resetValues()
  }

  function resetValues() {
    form.resetFields()
    setReason("")
  }

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setFilterConfig((prev) => ({ ...prev, term: searchTerm }))
    }
  }

  const handleRowClick = (rowData) => {
    setEmployeeDetails({
      id: rowData.employee_id,
      date: rowData.date,
      current_shift_id: rowData.shift_id,
    })
    setClickedRowData(rowData)
    const formattedValues = { ...rowData }
    formattedValues.date = formattedValues.date
      ? dayjs(formattedValues.date, dateFormat)
      : ""
    formattedValues.overtime = moment(rowData.overtime, "hh:mm A")
    formattedValues.is_timein = rowData.is_timein === "1" ? true : false
    form.setFieldsValue(formattedValues)
    setShowViewModal(true)
  }

  const rowProps = (record) => ({
    onClick: () => handleRowClick(record),
  })

  function checkDateInRange(date, startDate, endDate) {
    return (
      (dayjs(date) >= dayjs(startDate) && dayjs(date) <= dayjs(endDate)) ||
      dayjs(date).format("YYYY-MM-DD") ===
        dayjs(startDate).format("YYYY-MM-DD") ||
      dayjs(date).format("YYYY-MM-DD") === dayjs(endDate).format("YYYY-MM-DD")
    )
  }

  function findEmployeeSchedule(employee, passedDate, fieldChanged) {
    if (fieldChanged === "employee_id") {
      const employeeSchedules = schedules.filter(
        (sched) => sched.employee_id === employee.id
      )
      if (employeeSchedules.length > 0) {
        setEmployeeDetails({ ...employeeDetails, id: employee.id })
      } else {
        toast.error("Employee has no valid schedule")
        form.setFieldsValue({ date: null, overtime: null })
        setEmployeeDetails({})
      }
    } else {
      const employeeSchedules = schedules.filter(
        (sched) => sched.employee_id === employeeDetails.id
      )
      if (employeeSchedules.length > 0) {
        const selectedDate = dayjs(passedDate).format("YYYY-MM-DD")
        if (selectedDate !== "Invalid Date") {
          setEmployeeDetails({ ...employeeDetails, date: selectedDate })
          const currentSched = employeeSchedules.find((sched) =>
            checkDateInRange(passedDate, sched.date_from, sched.date_to)
          )
          if (currentSched) {
            const shiftDay = dayjs(passedDate).format("dddd")?.toLowerCase()
            setEmployeeDetails({
              ...employeeDetails,
              current_shift_id: currentSched[`${shiftDay}_shift`],
            })
          } else {
            setEmployeeDetails({ ...employeeDetails, current_shift_id: null })
          }
        } else {
          setEmployeeDetails({
            ...employeeDetails,
            current_shift_id: null,
            date: null,
          })
        }
      } else {
        toast.error("Employee has no valid schedule")
        form.setFieldsValue({ date: null, overtime: null })
        setEmployeeDetails({})
      }
    }
  }

  React.useEffect(() => {
    async function fetchTableData() {
      setIsFetching(true)
      setTableData([])
      const response = await UseGetRequest("overtimes/get", filterConfig)
      if (response.data) {
        setTableData(response.data.overtimes)
      }
      setIsFetching(false)
    }

    fetchTableData()
  }, [filterConfig])

  React.useEffect(() => {
    async function fetchSchedules() {
      setIsFetching(true)
      setTableData([])
      const response = await UseGetRequest("schedules/get")
      if (response.data) {
        setSchedules(response.data.data)
      }
      setIsFetching(false)
    }

    fetchSchedules()
  }, [])

  return (
    <Navbar
      pageTitle="Overtimes"
      pageButtonTitle="Add Overtime"
      onClickButton={() => {
        setShowAddModal(true)
        setShowViewModal(false)
      }}
      noButton={
        window.$isEmployeeLogin &&
        employeeOptions[1]?.salary_type === "flexi_monthly_26"
      }
      searchPlaceholder="employee"
      setSearchQuery={(e) => setSearchTerm(e)}
      onSearch={handleSearch}
      isManager
      searchable={isAdminLogin}
    >
      <Tabs
        defaultActiveKey="pending"
        className="mt-4"
        items={[
          { key: "pending", label: "Pending" },
          { key: "approved", label: "Approved" },
          { key: "disapproved", label: "Disapproved" },
          { key: "", label: "All" },
        ]}
        type="card"
        onChange={(e) => setFilterConfig((prev) => ({ ...prev, status: e }))}
      />
      <Table
        className=""
        rowKey="id"
        columns={tableColumns}
        dataSource={tableData}
        loading={isFetching}
        onRow={(record) => ({
          ...rowProps(record),
          style: { cursor: "pointer" },
        })}
      />
      <Modal
        maskClosable={false}
        title={`${showAddModal ? "Add" : "Edit"} Overtime`}
        centered
        open={showAddModal || showEditModal}
        footer={null}
        onCancel={() => {
          setShowAddModal(false)
          setShowEditModal(false)
          resetValues()
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={showAddModal ? onFinish : onEdit}
          initialValues={{
            is_timein: false,
            employee_id: window.$isEmployeeLogin ? getEmployeeId() : "",
          }}
        >
          <Row>
            <Col xs={24} lg={12}>
              <Form.Item
                name="employee_id"
                label="Employee"
                rules={[
                  {
                    required: true,
                  },
                ]}
                placeholder="Select Employee"
              >
                <Select
                  options={employeeOptions
                    .slice(1)
                    .filter((emp) => emp.salary_type !== "flexi_monthly_26")}
                  filterOption={(input, option) =>
                    option.label?.toLowerCase().includes(input?.toLowerCase())
                  }
                  onChange={(e, obj) =>
                    findEmployeeSchedule(obj, null, "employee_id")
                  }
                  showSearch
                />
              </Form.Item>
            </Col>

            <Col xs={24} lg={10}>
              <Form.Item
                name="date"
                label="Date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker
                  placeholder="YYYY-MM-DD"
                  disabled={!employeeDetails.id}
                  onChange={(e, obj) => findEmployeeSchedule(null, e, "date")}
                  // disabledDate={(date) => employeeDetails.salary_type!=="monthly22"&&date&&date.day()===6}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="overtime"
                label="Overtime"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TimePicker
                  format={"hh:mm A"}
                  disabled={!employeeDetails.current_shift_id}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Row>
                <Button
                  className="me-2"
                  danger
                  onClick={() => {
                    setShowAddModal(false)
                    setShowEditModal(false)
                    resetValues()
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <AddSubmitButton form={form} isLoading={isLoading} />
              </Row>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Review Overtime"
        centered
        open={showViewModal}
        footer={null}
        onCancel={() => {
          setShowViewModal(false)
          resetValues()
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="editForm"
          layout="vertical"
        >
          <Row>
            <Col xs={24} lg={12}>
              <Form.Item
                name="employee_id"
                label="Employee"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  options={employeeOptions.slice(1)}
                  searchable
                  disabled
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={10}>
              <Form.Item
                name="date"
                label="Date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker disabled />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="overtime"
                label="Overtime"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TimePicker format={"hh:mm A"} disabled />
              </Form.Item>
            </Col>
          </Row>

          {!window.$isEmployeeLogin && (
            <Form.Item>
              <Row className="d-flex justify-content-end mt-4">
                <Button
                  danger
                  onClick={() => {
                    setShowViewModal(false)
                    resetValues()
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <Button
                  type="text"
                  onClick={() => {
                    setShowViewModal(false)
                    setShowEditModal(true)
                  }}
                  icon={<EditOutlined />}
                  disabled={clickedRowData.status === "approved"}
                >
                  Edit
                </Button>
                {(isAdminLogin || role === "hr") && (
                  <Button
                    type="text"
                    onClick={() => {
                      confirmApprove(clickedRowData, "approve")
                    }}
                    icon={<CheckCircleOutlined />}
                    disabled={clickedRowData.status === "approved"}
                  >
                    Approve
                  </Button>
                )}
                <Button
                  type="text"
                  onClick={() => {
                    confirmApprove(clickedRowData, "disapprove")
                  }}
                  icon={<CloseCircleOutlined />}
                  disabled={clickedRowData.status === "disapproved"}
                  style={{ color: "red" }}
                >
                  Disapprove
                </Button>
                <Button
                  type="text"
                  danger
                  onClick={() => {
                    setShowConfirmDelete(true)
                    // confirmDelete(clickedRowData);
                  }}
                  icon={<DeleteOutlined />}
                  disabled={clickedRowData.status === "approved"}
                >
                  Delete
                </Button>
              </Row>
            </Form.Item>
          )}
        </Form>
      </Modal>
      <Modal
        open={fileSizeExceeded}
        title={""}
        footer={null}
        onCancel={() => setFileSizeExceeded(false)}
      >
        File size must not exceed 5MB.
      </Modal>

      <Modal
        footer={null}
        open={showConfirmDelete}
        onCancel={() => setShowConfirmDelete(false)}
      >
        <div className="p-2">
          <Row>
            <Title level={5}>
              <ExclamationCircleOutlined /> Are you sure you want to delete this
              overtime?
            </Title>
          </Row>
          <Row className="ms-3 mt-1">You cannot undo this action.</Row>
          <Row className="ms-3">
            <Input
              placeholder="Enter reason for deletion"
              onChange={(e) => setReason(e.target.value)}
              value={reason}
            />
          </Row>
          <Row className="mt-4 d-flex justify-content-end">
            <Space>
              <Button onClick={() => setShowConfirmDelete(false)}>
                Cancel
              </Button>
              <Button
                danger
                onClick={() => handleDelete(clickedRowData.id)}
                disabled={reason.trim() === ""}
              >
                Confirm
              </Button>
            </Space>
          </Row>
        </div>
      </Modal>
      {contextHolder}
    </Navbar>
  )
}
